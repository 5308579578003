import React from "react"
import {Link} from 'react-router-dom';

export default (props) => {
  return (
      <React.Fragment>
        <div className="c-product-list">
          <div className="o-flex">
            <div className="c-product-list__image">
              {props?.maxDiscountPercentage > 0 &&
                <div className="c-product-list__tags"><div className="c-product-list__sale">{props.maxDiscountPercentage}% Off</div></div>
              }
              <Link to={'/product/' + props.productId}
                  // onClick={() => window.location.reload(true)}
              >
                <img alt="" data-sizes="auto"
                    // data-srcset={props.images[0].src.replace('.jpg', '_large.jpg').replace('.png', '_large.png').replace('.gif', '_large.gif') + ' 270w'}
                     src={props.imageUrl} className="lazyload"/>
              </Link>
            </div>
            <div className="c-product-list-meta">
              <h5 className="c-product-list-meta__heading">
                <Link to={'/product/' + props.productId}
                    // onClick={() => window.location.reload(true)}
                >{props.name}</Link>
              </h5>
              {/*<h6 className="c-product-list-meta__price">*/}
              {/*  {props.variants[0].compareAtPrice != null ? (*/}
              {/*    <React.Fragment>*/}
              {/*      <span className="c-price__new">${props.variants[0].price}</span> <span className="c-price__old">${props.variants[0].compareAtPrice}</span>*/}
              {/*    </React.Fragment>*/}
              {/*  ) : (*/}
              {/*    <React.Fragment>*/}
              {/*      <span>${props.variants[0].price}</span>*/}
              {/*    </React.Fragment>*/}
              {/*  )}*/}
              {/*</h6>*/}
              <div className="c-product-list-meta__description">
                <meta charSet="utf-8"/>
                <span>{props.desc}</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  )
}