import React from "react"
import {Link} from 'react-router-dom';
import {useShopify} from "../redux/ducks/shopify";

export default (props) => {
  const {collections} = useShopify()
  return (
      <React.Fragment>
        <div className="c-header-mobile__menu slide-menu__control" data-target="mobile-menu" data-action="open">
          <i className="e-icons e-icons--icon-menu slide-menu__control" data-target="mobile-menu"
             data-action="open"></i>
        </div>
        <nav className="slide-menu js-mobile-menu" id="mobile-menu">
          <div className="controls">
            <div className="slide-menu__control slide-menu__control--close" data-target="mobile-menu"
                 data-action="close">
              <button type="button" id="close-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.24" height="33.25" viewBox="0 0 33.24 33.25">
                  <path id="x_copy_2" data-name="x copy 2" className="cls-1"
                        d="M2712.2,106.883l1.42,1.414-31.82,31.819-1.42-1.413Zm-30.4,0,31.82,31.82-1.42,1.413L2680.38,108.3Z"
                        transform="translate(-2680.38 -106.875)"/>
                </svg>
              </button>
            </div>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/collections/all">Categories</Link>
              <ul>
                {collections.map((collection, i) => {
                    return (
                        <li key={i}>
                          <Link className="field" to={'/collections/' + collection.categoryId}
                                onClick={() => {
                                  document.getElementById('close-button').click()
                                }}>
                            {collection.name}
                          </Link>
                        </li>
                    )
                })}
              </ul>
            </li>
            <li>
              <Link to="/collections/all" onClick={() => {
                document.getElementById('close-button').click()
              }}>Products
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link to="/blogs/news">Blog</Link>*/}
            {/*</li>*/}
            <li>
              <Link to="#">Pages</Link>
              <ul>
                <li>
                  <Link to="/pages/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/pages/about-us">About Us</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/pages/faqs">FAQs</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/pages/terms-of-use">Terms Of Use</Link>*/}
                {/*</li>*/}
              </ul>
            </li>
          </ul>
        </nav>
      </React.Fragment>
  )
}