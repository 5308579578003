import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>FAQs</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>FAQs</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-faq">
          <div className="o-wrapper">
            <div className="o-layout o-layout--custom">
              <div className="o-layout__item o-layout__item--6">
                <div id="shopify-section-faq" className="shopify-section">
                  <div className="c-faq__question">
                    <p className="c-question__number">Q1</p>
                    <h4 className="c-question__question">I use RANDOM.ORG alot. How can i show my appreciation?</h4>
                    <div className="c-question__answer">KERA works with international model agencies with the selection
                      process being carried out at our Head Office. If you are interested in modelling, please contact a
                      model agency.
                    </div>
                  </div>
                  <div className="c-faq__question">
                    <p className="c-question__number">Q2</p>
                    <h4 className="c-question__question">Is the source code for generator available?</h4>
                    <div className="c-question__answer">KERA works with international model agencies with the selection
                      process being carried out at our Head Office. If you are interested in modelling, please contact a
                      model agency.
                    </div>
                  </div>
                  <div className="c-faq__question">
                    <p className="c-question__number">Q3</p>
                    <h4 className="c-question__question">How do i apply for a job at KERA?</h4>
                    <div className="c-question__answer">KERA works with international model agencies with the selection
                      process being carried out at our Head Office. If you are interested in modelling, please contact a
                      model agency.
                    </div>
                  </div>
                  <div className="c-faq__question">
                    <p className="c-question__number">Q4</p>
                    <h4 className="c-question__question">Will RANDOM.ORG be around in 10 years?</h4>
                    <div className="c-question__answer">KERA works with international model agencies with the selection
                      process being carried out at our Head Office. If you are interested in modelling, please contact a
                      model agency.
                    </div>
                  </div>
                  <div className="c-faq__question">
                    <p className="c-question__number">Q5</p>
                    <h4 className="c-question__question">How do i become a model for TILANO?</h4>
                    <div className="c-question__answer">KERA works with international model agencies with the selection
                      process being carried out at our Head Office. If you are interested in modelling, please contact a
                      model agency.
                    </div>
                  </div>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--6 u-sticky-top">
                <form action="/contact#contact_form" id="contact_form" className="contact-form">
                  <div className="o-layout o-layout--custom">
                    <div className="o-layout__item o-layout__item--4">
                      <input type="text" id="ContactFormName" name="contact[name]" placeholder="Name"/>
                    </div>
                    <div className="o-layout__item o-layout__item--4">
                      <input type="email" id="ContactFormEmail" name="contact[email]" placeholder="E-mail"/>
                    </div>
                    <div className="o-layout__item o-layout__item--4">
                      <input type="tel" id="ContactFormPhone" name="contact[phone]" placeholder="Phone"/>
                    </div>
                  </div>
                  <textarea rows={10} id="ContactFormMessage" name="contact[body]" placeholder="Message"/>
                  <input type="submit" className="c-btn c-btn--black" value="Submit Question"/>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}