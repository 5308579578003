import React from 'react';
import {Link} from 'react-router-dom';
import {ADDRESS, EMAIL, PROVINCE_COUNTRY, TEL_LAND, TEL_MOBILE} from "../resources/Client";

export default (props) => {
  return (
      <footer className="c-footer">
        <div className="o-wrapper">
          <div className="o-layout o-layout--custom">
            <div className="o-layout__item o-layout__item--5">
              <div className="c-footer__newsletter">
                {/*<form>*/}
                {/*  <div className="c-newsletter__wrap-fields">*/}
                {/*    <input className="c-footer__input" type="email" required="required" name="EMAIL" placeholder="Your email" />*/}
                {/*    <button className="c-footer__button" type="submit">subscribe &amp; get $15 coupon</button>*/}
                {/*  </div>*/}
                {/*</form>*/}
              </div>
            </div>
            <div className="o-layout__item o-layout__item--2">
              <ul className="c-footer__menu">
                <li>
                  <Link to="/pages/about-us">About</Link>
                </li>
                <li>
                  <Link to="/pages/contact-us">Contact</Link>
                </li>
                <li>
                  <Link to="/collections/all">Shop</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/blogs/news">Blog</Link>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="o-layout__item o-layout__item--2">
              <ul className="c-footer__menu">
                <li>
                  <a href="https://www.facebook.com/Smart-Printing-Packaging-111982957193183/"  rel="noopener noreferrer"
                     target="_blank">Facebook
                  </a>
                </li>
                {/*<li>*/}
                {/*  <a href="http://instagram.com" target="_blank">Instagram</a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a href="http://twitter.com" target="_blank">Twitter</a>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="o-layout__item o-layout__item--3">
              <div className="c-footer__text">{TEL_LAND}
                <br/>
                {TEL_MOBILE}
                <br/>
                <a href="mailto:info@smartprinting.lk">{EMAIL}</a>
                <br/>
                {ADDRESS}
                <br/>
                {PROVINCE_COUNTRY}
              </div>
            </div>
          </div>
          <div className="c-copyright" style={{marginBottom:"35px", textAlign:"center"}}>
            <div className="c-copyright__text">
              <span className="mr-1">Powered By </span>
              <a href="https://www.techneolabs.com"
                 target="_blank" rel="noopener noreferrer" style={{fontFamily: 'Times New Roman'}}>
                <strong><span style={{color: '#3ec1d5'}}>Techneo</span>Labs</strong>
              </a>
            </div>
          </div>
        </div>
      </footer>
  )
}