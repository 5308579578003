import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {COMPANY_NAME} from "../resources/Client";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>About Us</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>About Us</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-about">
          {/*<div className="c-about__background">*/}
          {/*  <img alt="About Us" data-sizes="auto" data-srcset="/images/bg-about.jpg 270w,*/}
          {/*  /images/bg-about.jpg 540w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="*/}
          {/*       className="lazyload"/>*/}
          {/*</div>*/}
          <div className="o-wrapper">
            <div className="c-about__content">
              <h4 className="c-block__heading">Welcome to {COMPANY_NAME} </h4>
              <div className="c-block__description">
                <p style={{textAlign: "justify"}}>
                  Smart Printing and Packaging is a leading offset printing and packaging company in Sri Lanka. We are
                  very well aware about our business role in the current society through our experiences in this
                  printing and packaging industry for more than 10 years. We believe that we’ve met our customer
                  satisfaction at its best at all times. In order to meet customer expectations, we, as a printing and
                  packaging company had always gone an extra mile and had ensured that the customer is satisfied with
                  the service received. Our services expand to a varied range, from leaflets, business cards, magazines,
                  booklets, posters, letter heads, bill books, file covers, labels and sticker printing etc.
                </p>
                <p style={{textAlign: "justify"}}>
                  The other major part in our company is ‘packaging’ and we provide wide range of packaging items,
                  namely shoe boxes, garment boxes, take away boxes, cake boxes, surgical mask boxes, all types of
                  pharmaceutical packages, tea cartons, master cartons etc.
                </p>
                <p style={{textAlign: "justify"}}>
                  We, also have a vast range of food packaging products to ensure that we cater for all, food packaging
                  requirements of hotels, restaurants, catering services etc.  We also provide kraft boxes, card board
                  take away boxes, aluminum foil boxes, pizza boxes, burger boxes, cupcake boxes, burger wrappers,
                  popcorn boxes etc.
                </p>
                <p style={{textAlign: "justify"}}>
                  Since we are dealing with, business to business, we ensure that our customers will have the best
                  printing and packaging products which will directly satisfy their customers, which will eventually
                  build a good relationship with their customers. We always communicate and suggest best options and
                  business developing strategies to our clients as their own printing and packaging partner.
                </p>
              </div>
              {/*<h4 className="c-block__heading">Our Story</h4>*/}
              {/*<div className="c-block__description">*/}
              {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pretium, massa vel varius semper,*/}
              {/*    massa felis tempus mauris, in convallis felis mauris in nunc. Vestibulum pretium ligula scelerisque,*/}
              {/*    maximus lorem hendrerit, vestibulum metus. Suspendisse in placerat augue. Nam imperdiet purus id*/}
              {/*    ccommodo luctus. Vestibulum ligula urna, condimentum mollis ornare ut, porta et arcu. Etiam commodo*/}
              {/*    efficitur magna, non aliquet nisi blandit quis. Sed congue aliquam sem, vehicula venenatis dolor*/}
              {/*    efficitur eu.*/}
              {/*  </p>*/}
              {/*  <iframe className="elementor-video-iframe" allowFullScreen title="youtube Video Player"*/}
              {/*          src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"/>*/}
              {/*  <p>*/}
              {/*    <strong>Etiam dictum porttitor malesuada. In justo massa, venenatis eu laoreet vel, pellentesque non*/}
              {/*      ligula. Nulla laoreet ut eros id maximus. Quisque odio orci, vulputate varius rutrum ut, elementum*/}
              {/*      id sapien.*/}
              {/*    </strong>*/}
              {/*  </p>*/}
              {/*  <p>Nunc pharetra quis diam in elementum. Nulla libero purus, lacinia et orci a, aliquam iaculis ante.*/}
              {/*    Fusce imperdiet volutpat felis ac tristique. Interdum et malesuada fames ac ante ipsum primis in*/}
              {/*    faucibus. Nunc viverra posuere arcu at varius. Morbi sit amet dolor ut augue interdum hendrerit et sit*/}
              {/*    amet felis. Vestibulum sit amet efficitur odio. Suspendisse non urna non turpis aliquam sagittis at*/}
              {/*    sit amet nulla.*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*<h4 className="c-block__heading">Our Team Member</h4>*/}
              {/*<div className="c-block__description">*/}
              {/*  <div className="o-layout o-layout--custom">*/}
              {/*    <div className="o-layout__item o-layout__item--3 c-team-member">*/}
              {/*      <img src="/images/team-01.jpg" alt="Person"/>*/}
              {/*      <p>*/}
              {/*        <strong>Kaylee Fritz</strong>*/}
              {/*        <span>Operations Manager</span>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <div className="o-layout__item o-layout__item--3 c-team-member">*/}
              {/*      <img src="/images/team-02.jpg" alt="Person"/>*/}
              {/*      <p>*/}
              {/*        <strong>Jayla Campbell</strong>*/}
              {/*        <span>CEO/CTO</span>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <div className="o-layout__item o-layout__item--3 c-team-member">*/}
              {/*      <img src="/images/team-04.jpg" alt="Person"/>*/}
              {/*      <p>*/}
              {/*        <strong>Fisher Garrison</strong>*/}
              {/*        <span>Marketing Manager</span>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <div className="o-layout__item o-layout__item--3 c-team-member">*/}
              {/*      <img src="/images/team-03.jpg" alt="Person"/>*/}
              {/*      <p>*/}
              {/*        <strong>Brynn Marshall</strong>*/}
              {/*        <span>Chief Financial Office</span>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}