import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useShopify} from "../../redux/ducks/shopify";
import {NotificationManager} from "react-notifications";
import {createUser} from "../../service/UserService";
import {Link} from "react-router-dom";

export default (props) => {

  const {getUser, setUser, emptyCart} = useShopify();

  const [loggedUser, setLoggedUser] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [loader, setLoader] = useState(false)


  function createAccount(event) {
    event.preventDefault();
    event.stopPropagation();
    setLoader(true);
    setUser({
      firstName: firstName,
      lastName: lastName,
      email: email,
      telephone: telephone,
      address: address,
      district: district !== undefined ? district : '',
    })
    createUser({
      firstName: firstName,
      lastName: lastName,
      email: email,
      telephone: telephone,
      address: (address !== undefined ? (address.concat("\n").concat(district !== undefined ? district : '')) : ''),
      status: 'A',
    }).then((response) => {
      if ('success' === response.status) {
        setLoader(false);
        NotificationManager.success('Account details has been saved.', 'Success', 4000);
        setLoggedUser(getUser())
      } else {
        NotificationManager.error('Oops! Something went wrong.', 'Error', 4000);
      }
    })
  }

  function signOut() {
    setUser({})
    emptyCart()
    setLoggedUser(getUser())
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoggedUser(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
      <React.Fragment>
        <Header homepage="true"/>
        <div className={loader ? 'c-login c-login--loading' : 'c-login'}>
          <div className="o-wrapper">
            <div className="c-filter__loading"></div>
            <div className="o-layout o-layout--custom">
              {loggedUser.telephone ?
                  <div className="o-layout__item o-layout__item--6">
                    <div className="c-login__box js-login-box m">
                      <h4 className="c-box__title">Account</h4>
                      <div className="c-account-div">
                        <i aria-hidden="true" className="fas fa-user"/>
                        <span
                            className="c-box__description c-account-field">{loggedUser.firstName} {loggedUser.lastName}</span>
                      </div>
                      <div className="c-account-div">
                        <i className="fas fa-phone-alt"></i>
                        <span className="c-box__description c-account-field">{loggedUser.telephone}</span>
                      </div>
                      <div className="c-account-div">
                        <i className="fas fa-at"></i>
                        <span className="c-box__description c-account-field">{loggedUser.email}</span>
                      </div>
                      <div className="c-account-div">
                        <i className="far fa-address-card"></i>
                        <span className="c-box__description c-account-field">
                          {loggedUser.address}{loggedUser.district && loggedUser.district !== '' ?
                            (', ').concat(loggedUser.district).concat(' District') : ''}</span>
                      </div>
                    </div>
                    <button className="c-btn c-btn--black c-account-div" onClick={() => {
                      signOut()
                    }}>Sign Out
                    </button>
                  </div>
                  :
                  <div className="o-layout__item o-layout__item--6">
                    <div className="c-login__box js-login-box">
                      <h4 className="c-box__title">Account Details</h4>
                      <form onSubmit={createAccount}>
                        <div className="o-layout o-layout--custom ">
                          <div className="o-layout__item o-layout__item--6">
                            <div className="c-form-group c-form--required">
                              <input type="text" required="required"
                                     placeholder="First Name" id="input-firstname"
                                     onChange={(e) => {
                                       setFirstName(e.target.value)
                                     }}
                              />
                            </div>
                          </div>
                          <div className="o-layout__item o-layout__item--6">
                            <div className="c-form-group">
                              <input type="text" placeholder="Last Name"
                                     id="input-lastname"
                                     onChange={(e) => {
                                       setLastName(e.target.value)
                                     }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="o-layout o-layout--custom">
                          <div className="o-layout__item o-layout__item--6">
                            <div className="c-form-group c-form--required">
                              <input type="text" required="required"
                                     placeholder="Telephone No" id="input-telephone"
                                     onChange={(e) => {
                                       setTelephone(e.target.value)
                                     }}
                              />
                            </div>
                          </div>
                          <div className="o-layout__item o-layout__item--6">
                            <div className="c-form-group c-form--required">
                              <input type="email" placeholder="E-Mail"
                                     id="input-email"
                                     onChange={(e) => {
                                       setEmail(e.target.value)
                                     }}
                              />
                            </div>
                          </div>
                        </div>
                        <h6 className="c-box__title">Delivery Information</h6>
                        <div className="c-form-group c-form--required">
                          <textarea type="text" rows="5" required="required"
                                    placeholder="Address" id="input-address"
                                    onChange={(e) => {
                                      setAddress(e.target.value)
                                    }}
                          />
                        </div>
                        <div className="c-form-group c-form--required">
                          <label htmlFor={"sizeOptions"}>District</label>
                          <select
                              id="districtOptions"
                              name="district"
                              onChange={(e) => {
                                setDistrict(e.target.value)
                              }}
                              required>
                            <option value="" defaultChecked={true}>Select District</option>
                            <option value="Colombo" key="1">Colombo</option>
                            <option value="Ampara" key="2">Ampara</option>
                            <option value="Anuradhapura" key="3">Anuradhapura</option>
                            <option value="Badulla" key="4">Badulla</option>
                            <option value="Batticaloa" key="5">Batticaloa</option>
                            <option value="Galle" key="6">Galle</option>
                            <option value="Gampaha" key="7">Gampaha</option>
                            <option value="Hambantota" key="8">Hambantota</option>
                            <option value="Jaffna" key="9">Jaffna</option>
                            <option value="Kalutara" key="10">Kalutara</option>
                            <option value="Kandy" key="11">Kandy</option>
                            <option value="Kegalle" key="12">Kegalle</option>
                            <option value="Kilinochchi" key="13">Kilinochchi</option>
                            <option value="Kurunegala" key="14">Kurunegala</option>
                            <option value="Mannar" key="15">Mannar</option>
                            <option value="Matale" key="16">Matale</option>
                            <option value="Matara" key="17">Matara</option>
                            <option value="Monaragala" key="18">Monaragala</option>
                            <option value="Mullaitivu" key="19">Mullaitivu</option>
                            <option value="Nuwara Eliya" key="20">Nuwara Eliya</option>
                            <option value="Polonnaruwa" key="21">Polonnaruwa</option>
                            <option value="Puttalam" key="22">Puttalam</option>
                            <option value="Ratnapura" key="23">Ratnapura</option>
                            <option value="Trincomalee" key="24">Trincomalee</option>
                            <option value="Vavuniya" key="25">Vavuniya</option>
                          </select>
                        </div>
                        <button type="submit" className="c-btn c-btn--black" disabled={loader}>Save Account Details
                        </button>
                      </form>
                    </div>
                  </div>
              }
              <div className="o-layout__item o-layout__item--6">
                <div className="c-banner">
                  <Link to="/collections/all" className="c-banner__image">
                    <img alt="Shop now" data-sizes="auto" data-srcset="/images/banner-login.jpeg 470w,
                  /images/banner-login.jpeg 940w"
                         src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                         className="lazyload"/>
                  </Link>
                  {/*<h3 className="c-banner__heading">*/}
                  {/*  <Link to="/collections/all">T shirt collections</Link>*/}
                  {/*</h3>*/}
                  {/*<p className="c-banner__description">Pellentesque eget dolor augue. Fusce augue urna, maximus non*/}
                  {/*  blandit sit amet.*/}
                  {/*</p>*/}
                  <Link to="/collections/all" className="c-btn c-banner__btn">Shop now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}