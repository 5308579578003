const GLOBAL = require('../resources/Globals');

const getPreSignedUrl = async (id, image) => {
  return await fetch(GLOBAL.URL_GET_PRE_SIGNED_S3_URL + id, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 201) {
      let responseJson = await response.json();
      let url = responseJson.data;
      return putImage(url, image).then(async value => {
        return value
      })
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get image put URL",
    }
  });
}

const putImage = async (url, image) => {
  let binary = atob(image.split(',')[1])
  let array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})
  return await fetch(url, {
    method: 'PUT',
    body: blobData
  }).then(async (response) => {
    if (response.status === 200) {
      return {
        status: "success",
        data: "Image uploaded",
      }
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot upload the image",
    }
  });
}

export const uploadImage = async (id, image) => {
  return getPreSignedUrl(id, image)
      .then(async (response) => {
            return response;
          }
      );
}

export const imageHeartBeat = async () => {
  return await fetch(GLOBAL.URL_HEARTBEAT_IMAGE, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data;
    }
  }).catch(error => {
    console.log("Image service heartbeat is failing")
    return {
      status: "error",
      data: "Image service heartbeat is failing",
    }
  });
}


