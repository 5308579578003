import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

export default (props) => {
  useEffect(()=>{
    let width = window.innerWidth;
    let maxWidth = 680;
    if (width >= maxWidth) {
      document.getElementById("logo-img").style.width = "448px";
      document.getElementById("logo-img").style.height = "80px";
    } else {
      document.getElementById("logo-img").style.width = "224px";
      document.getElementById("logo-img").style.height = "40px";
    }
  })
  return (
      <div className="e-logo">
        <Link to="/" className="e-logo__link">
          {/*<svg xmlns="http://www.w3.org/2000/svg" width="90" height="26" viewBox="0 0 296 85">*/}
          {/*  <text id="Kera." className="cls-1" transform="translate(-10.333 83.889) scale(8.883 8.976)">Kera.</text>*/}
          {/*</svg>*/}
          {/*<svg xmlns="http://www.w3.org/2000/svg" width="90" height="26" viewBox="0 0 296 85">*/}
          <img id="logo-img" src="/header-logo.png" className="d-inline-block align-top"
               alt="SPAP Logo"/>
          {/*<text id="Kera." className="cls-1" transform="translate(-10.333 83.889) scale(8.883 8.976)">Smart Printing & Packaging.</text>*/}
          {/*</svg>*/}
        </Link>
      </div>
  )
}