import React from 'react';
import {Link} from 'react-router-dom';
import {useShopify} from "../redux/ducks/shopify";

export default (props) => {
  const {collections} = useShopify()


  return (
      <ul className="c-header-nav">
        <li className="c-header__nav-item ">
          <Link className="c-header__nav-link" to="/">Home</Link>
        </li>
        <li className="c-header__nav-item js-nav-item">
          <Link className="c-header__nav-link " to="/collections/all">Categories</Link>
          <div className="c-header__sub-menu c-header__sub-menu--mega-menu">
            <div className="o-layout o-layout--custom">
              <div className="o-layout__item o-layout__item--6">
                {/*<h3 className="c-menu__heading">*/}
                {/*  <Link to="/collections/all">What's new in?</Link>*/}
                {/*</h3>*/}
                <ul className="c-menu__sub-menu">
                  {collections.map((collection, i) => {
                    return ((i % 2 === 0) ? <li key={i} className="c-menu__item">
                      <Link className="field" to={'/collections/' + collection.categoryId}>
                        <img src={collection.imageUrl} alt={collection.name} class="avatar" style={{paddingRight: '2px'}} /> {collection.name}
                      </Link>
                    </li> : <></>)
                  })}
                </ul>
              </div>
              <div className="o-layout__item o-layout__item--6">
                {/*<h3 className="c-menu__heading">*/}
                {/*  <Link to="/collections/all">Product</Link>*/}
                {/*</h3>*/}
                <ul className="c-menu__sub-menu">
                  {collections.map((collection, i) => {
                    return ((i % 2 === 1) ? <li key={i} className="c-menu__item">
                      <Link className="field" to={'/collections/' + collection.categoryId}>
                        <img src={collection.imageUrl} alt={collection.name} className="avatar"/> {collection.name}
                      </Link>
                    </li> : <></>)
                  })}
                  {/*{collections.map((collection, i) => {*/}
                  {/*  { return ((i%2===1) ?*/}
                  {/*      <li className="o-flex o-flex--align-items-center" key={i}>*/}
                  {/*        <div className="c-cart-product__image" style={{ maxWidth: '25%' }}>*/}
                  {/*          {collection.imageUrl ? (*/}
                  {/*              <Link to={'/collections/' + collection.categoryId}*/}
                  {/*                    onClick={() => window.location.href = "/collections/" + collection.categoryId}>*/}
                  {/*                <img*/}
                  {/*                    src={collection.imageUrl}*/}
                  {/*                    alt={ `{collection.name} product shot`}*/}
                  {/*                />*/}
                  {/*              </Link>*/}
                  {/*          ) : null}*/}
                  {/*        </div>*/}
                  {/*        <div className="c-cart-product__right">*/}
                  {/*          <div className="c-cart-product__name">*/}
                  {/*            <Link to={'/collections/' + collection.categoryId}*/}
                  {/*                  onClick={() => window.location.href = "/collections/" + collection.categoryId}>{collection.name}<br />*/}
                  {/*             </Link>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      </li>: <></>) }*/}
                  {/*})}*/}
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li className="c-header__nav-item ">
          <Link className="c-header__nav-link " to="/collections/all">Products</Link>
        </li>
        {/*<li className="c-header__nav-item ">*/}
        {/*  <Link className="c-header__nav-link " to="/blogs/news">Blog</Link>*/}
        {/*</li>*/}
        <li className="c-header__nav-item js-nav-item">
          <Link className="c-header__nav-link " to="#">Pages</Link>
          <div className="c-header__sub-menu">
            <ul className="c-menu__sub-menu">
              <li className="c-menu__item">
                <Link to="/pages/contact-us">Contact Us</Link>
              </li>
              <li className="c-menu__item">
                <Link to="/pages/about-us">About Us</Link>
              </li>
              {/*<li className="c-menu__item">*/}
              {/*  <Link to="/pages/faqs">FAQs</Link>*/}
              {/*</li>*/}
              {/*<li className="c-menu__item">*/}
              {/*  <Link to="/pages/terms-of-use">Terms Of Use</Link>*/}
              {/*</li>*/}
            </ul>
          </div>
        </li>
      </ul>
  )
}