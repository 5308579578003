import React, {useEffect} from "react"
import {Redirect, Route, Switch, BrowserRouter as Router} from "react-router-dom"
import Home from "../templates/Index"
import ErrorPage from '../templates/404';
import {useShopify} from "../hooks"
import {NotificationContainer} from 'react-notifications';
import Blog from '../templates/Blog';
import Article from '../templates/Article';
import Collection from "../templates/Collection"
import Product from "../templates/Product"
import Wishlist from "../templates/Wishlist"
import Login from "../templates/account/Login"
import ContactUs from "../templates/ContactUs"
import AboutUs from "../templates/AboutUs"
import Faq from "../templates/Faq"
import TermsOfUse from "../templates/TermsOfUse"
import Cart from "../snippets/Cart";
import Invoice from "../snippets/Invoice";

export default (props) => {
  const {
    createShop,
    createCheckout,
    fetchProducts,
    fetchCollections,
  } = useShopify()

  useEffect(() => {
    createShop()
    fetchProducts()
    createCheckout()
    fetchCollections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <Router>
        <React.Suspense>
          <div id="app">
            <Switch>
              <Route exact path="/" render={() => <Redirect from="/" to="home"/>}/>
              <Route path="/home" component={Home}/>
              <Route path="/blogs/:blogId/:articleId" component={Article}/>
              <Route path="/blogs/:blogId" component={Blog}/>
              <Route path="/collections/:collectionId" component={Collection}/>
              <Route path="/product/:productId" component={Product}/>
              <Route path="/pages/wishlist" component={Wishlist}/>
              <Route path="/pages/contact-us" component={ContactUs}/>
              <Route path="/pages/about-us" component={AboutUs}/>
              <Route path="/pages/faqs" component={Faq}/>
              <Route path="/pages/terms-of-use" component={TermsOfUse}/>
              <Route path="/account/login" component={Login}/>
              <Route path="/cart" component={Cart}/>
              <Route path="/invoice/:orderId" component={Invoice}/>
              <Route component={ErrorPage}/>
            </Switch>
            {/*<Redirect from="/" to="/home"/>*/}
          </div>
          <NotificationContainer/>
        </React.Suspense>
      </Router>
  )
}
