import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useShopify} from "../redux/ducks/shopify";
import {Link, useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {placeOrder} from "../service/OrderService";

export default (props) => {
  const {checkoutState, setUser, getUser, emptyCart} = useShopify()

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [orderType, setOrderType] = useState('');
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const history = useHistory();

  const [loader, setLoader] = useState(true)


  function createOrder(event) {
    event.preventDefault();
    event.stopPropagation();
    setLoader(false)
    let form = document.getElementById("orderForm");
    let elements = form.elements;
    for (let i = 0, len = elements.length; i < len; ++i) {
      elements[i].readOnly = true;
      elements[i].disabled = true;
    }
    setUser({
      firstName: firstName,
      lastName: lastName,
      email: email,
      telephone: telephone,
      address: address,
      district: district !== undefined ? district : '',
    })
    let orderDetail = [];
    Array.from(checkoutState.values()).map((lineItem, index) => {
      orderDetail.push({
        productDetailId: lineItem.productDetail.productDetailId,
        qty: lineItem.quantity,
        cost: lineItem.productDetail.cost,
        price: lineItem.productDetail?.discountPercentage > 0 ? (lineItem.productDetail.price - (((lineItem.productDetail.price * lineItem.productDetail?.discountPercentage)/100).toFixed(2))) : lineItem.productDetail.price,
        originalPrice: lineItem.productDetail.price
      })
      return orderDetail;
    })
    placeOrder({
      firstName: firstName,
      lastName: lastName,
      email: email,
      telephone: telephone,
      address: (address !== undefined ? (address.concat("\n").concat(district !== undefined ? district : '')) : ''),
      status: 'A',
    }, {
      amount: calculateSubTotal(),
      discount: calculateTotalDiscount(),
      deliveryAmount: orderType === 'delivery' ? calculateDeliveryAmount() : null,
      orderType: orderType,
      orderDetailDtoList: orderDetail,
    }).then((response) => {
      setLoader(true)
      for (let i = 0, len = elements.length; i < len; ++i) {
        elements[i].readOnly = false;
        elements[i].disabled = false;
      }
      if ('success' === response.status) {
        NotificationManager.success('Order has been placed successfully. Invoice No: ' + response.data, 'Success', 4000);
        // response.data.invoiceNo
        //TODO create invoice before empty the card
        emptyCart();
        history.push('/invoice/' + response.data)

      } else {
        NotificationManager.error('Oops! Something went wrong. Order is not placed.', 'Error', 4000);
      }
    })
  }

  function calculateTotalDiscount() {
    let totalDiscount = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      let discountPrice = lineItem.productDetail?.discountPercentage > 0
          ? ((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100).toFixed(2)
          : 0
      totalDiscount += (lineItem.quantity * discountPrice)
      return totalDiscount;
    });
    return totalDiscount
  }
  function calculateSubTotal() {
    let subTotal = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      let price = lineItem.productDetail?.discountPercentage > 0
          ? (lineItem.productDetail.price - (((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100).toFixed(2)))
          : lineItem.productDetail.price
      subTotal += (lineItem.quantity * price)
      return subTotal;
    });
    return subTotal
  }

  function calculateTotalAmount() {
    return calculateSubTotal() + calculateDeliveryAmount();
  }

  function calculateDeliveryAmount() {
    if (orderType === 'delivery') {
      let deliveryAmount = 0;
      if (district === 'Colombo') {
        deliveryAmount = deliveryAmount + 350;
      } else {
        deliveryAmount = deliveryAmount + 400;
      }
      let weight = calculateTotalWeight();
      if (weight > 1000) {
        deliveryAmount = deliveryAmount + Math.ceil((weight - 1000) / 1000) * 80;
      }
      return deliveryAmount;
    } else {
      return 0;
    }
  }

  function calculateTotalWeight() {
    let totalWeight = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      totalWeight += (lineItem.quantity * lineItem.productDetail.weight)
      return totalWeight;
    });
    return totalWeight
  }

  let subTotal = calculateSubTotal();
  let totalDiscount = calculateTotalDiscount();
  let totalWeight = calculateTotalWeight();
  let subTotalWithDeliveryCharge = calculateTotalAmount();

  useEffect(() => {
    window.scrollTo(0, 0);
    let user = getUser();
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setTelephone(user.telephone)
    setEmail(user.email)
    setAddress(user.address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
      <React.Fragment>
        <Header homepage="true"/>
        <div className={loader ? 'c-login' : 'c-login c-login--loading'}>
          <div className="o-wrapper">
            <div className="c-filter__loading"></div>
            <div className="o-layout o-layout--custom">
              <div className="o-layout__item o-layout__item--8">
                <div className="c-login__box js-login-box">
                  <h4 className="c-box__title">Information</h4>
                  <form onSubmit={createOrder} id="orderForm">
                    <div className="o-layout o-layout--custom ">
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form-group c-form--required">
                          <input type="text" required="required"
                                 placeholder="First Name" id="input-firstname"
                                 value={firstName}
                                 onChange={(e) => {
                                   setFirstName(e.target.value)
                                 }}
                          />
                        </div>
                      </div>
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form-group">
                          <input type="text" placeholder="Last Name"
                                 id="input-lastname"
                                 value={lastName}
                                 onChange={(e) => {
                                   setLastName(e.target.value)
                                 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="o-layout o-layout--custom">
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form-group c-form--required">
                          <input type="text" required="required"
                                 placeholder="Telephone No" id="input-telephone"
                                 value={telephone}
                                 onChange={(e) => {
                                   setTelephone(e.target.value)
                                 }}
                          />
                        </div>
                      </div>
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form-group c-form--required">
                          <input type="email" placeholder="E-Mail"
                                 id="input-email"
                                 value={email}
                                 onChange={(e) => {
                                   setEmail(e.target.value)
                                 }}
                          />
                        </div>
                      </div>
                    </div>
                    <br/>
                    <h6 className="c-box__title">Order Type</h6>
                    <div className="o-layout o-layout--custom">
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form--required" style={{flexDirection: "row"}}>
                          <input type="radio" required="required"
                                 style={{width: "auto", display: "inline-flex", marginRight: "10px"}}
                                 id="radio-pickup" name="order-type" value="pickup"
                                 onChange={(e) => {
                                   setOrderType(e.target.value)
                                 }}
                          />
                          <label htmlFor="radio-pickup" style={{fontSize: "16px", color: "black"}}>Pickup from outlet
                          </label>
                        </div>
                      </div>
                      <div className="o-layout__item o-layout__item--6">
                        <div className="c-form--required" style={{flexDirection: "row"}}>
                          <input type="radio" required="required"
                                 style={{width: "auto", display: "inline-flex", marginRight: "10px"}}
                                 id="radio-delivery" name="order-type" value="delivery"
                                 onChange={(e) => {
                                   setOrderType(e.target.value)
                                 }}
                          />
                          <label htmlFor="radio-delivery" style={{fontSize: "16px", color: "black"}}>Deliver to my
                            location
                          </label>
                        </div>
                      </div>
                    </div>
                    <br/>

                    {orderType && orderType === 'delivery' &&
                    <>
                      <h6 className="c-box__title">Delivery Information</h6>
                      <div className="c-form-group c-form--required">
                        <textarea type="text" rows="5" required="required"
                                  placeholder="Address" id="input-address"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value)
                                  }}
                        />
                      </div>
                      <div className="c-form-group c-form--required">
                        <label htmlFor={"sizeOptions"}>District</label>
                        <select
                            id="districtOptions"
                            name="district"
                            onChange={(e) => {
                              setDistrict(e.target.value)
                              calculateDeliveryAmount()
                              calculateTotalAmount()
                            }}
                            required>
                          <option value="" defaultChecked={true}>Select District</option>
                          <option value="Colombo" key="1">Colombo</option>
                          <option value="Ampara" key="2">Ampara</option>
                          <option value="Anuradhapura" key="3">Anuradhapura</option>
                          <option value="Badulla" key="4">Badulla</option>
                          <option value="Batticaloa" key="5">Batticaloa</option>
                          <option value="Galle" key="6">Galle</option>
                          <option value="Gampaha" key="7">Gampaha</option>
                          <option value="Hambantota" key="8">Hambantota</option>
                          <option value="Jaffna" key="9">Jaffna</option>
                          <option value="Kalutara" key="10">Kalutara</option>
                          <option value="Kandy" key="11">Kandy</option>
                          <option value="Kegalle" key="12">Kegalle</option>
                          <option value="Kilinochchi" key="13">Kilinochchi</option>
                          <option value="Kurunegala" key="14">Kurunegala</option>
                          <option value="Mannar" key="15">Mannar</option>
                          <option value="Matale" key="16">Matale</option>
                          <option value="Matara" key="17">Matara</option>
                          <option value="Monaragala" key="18">Monaragala</option>
                          <option value="Mullaitivu" key="19">Mullaitivu</option>
                          <option value="Nuwara Eliya" key="20">Nuwara Eliya</option>
                          <option value="Polonnaruwa" key="21">Polonnaruwa</option>
                          <option value="Puttalam" key="22">Puttalam</option>
                          <option value="Ratnapura" key="23">Ratnapura</option>
                          <option value="Trincomalee" key="24">Trincomalee</option>
                          <option value="Vavuniya" key="25">Vavuniya</option>
                        </select>
                      </div>
                    </>
                    }
                    <button type="submit" className="c-btn c-btn--black" disabled={!loader}>Place the order</button>
                  </form>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-login__box c-login__box--register">
                  <h4 className="c-box__title">Item List</h4>
                  <div>
                    <ul className="c-header-cart__items">
                      {checkoutState && checkoutState.size > 0 &&
                      Array.from(checkoutState.values()).map((lineItem, index) => {
                        return (
                            <li className="o-flex o-flex--align-items-center"
                                style={{"justify-content": "space-between"}} key={index}>
                              <div className="c-cart-product__image" style={{width: '25%'}}>
                                {lineItem.product.imageUrl ? (
                                    <Link to={'/product/' + lineItem.product.productId}
                                        // onClick={() => window.location.reload(true)}
                                    >
                                      <img
                                          src={lineItem.product.imageUrl}
                                          alt={`{lineItem.product.name} product shot`}
                                      />
                                    </Link>
                                ) : null}
                              </div>
                              <div className="c-cart-product__right" style={{width: '50%'}}>
                                <div className="c-cart-product__name">
                                  <Link to={'/product/' + lineItem.product.productId}
                                      // onClick={() => window.location.reload(true)}
                                  >{lineItem.product.name}
                                    <br/>
                                    {lineItem.productDetail.size} - {lineItem.productDetail.colour}
                                  </Link>
                                </div>
                                <div
                                    className="c-cart-product__price">Weight: {lineItem.quantity} x {(lineItem.productDetail.weight)}g
                                </div>
                                <div
                                    className="c-cart-product__price">{lineItem.quantity} x <strong>{lineItem.productDetail?.discountPercentage > 0
                                      ? <><s style={{color: "red"}}><span>Rs.{(lineItem.productDetail.price).toFixed(2)}</span></s> <span style={{color: "green"}}>Rs.{(lineItem.productDetail.price - ((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100)).toFixed(2)}</span></>
                                      : <span>Rs.{(lineItem.productDetail.price).toFixed(2)}</span>
                                  }</strong>
                                  {/*<strong>Rs.{(lineItem.productDetail.price).toFixed(2)}</strong>*/}
                                </div>
                              </div>
                              <div className="align-content-end" style={{width: '25%'}}>
                                <div className="c-cart-product__price"
                                     style={{"float": "right"}}>
                                  <strong style={{color: lineItem.productDetail?.discountPercentage > 0 && "green"}}>
                                    Rs.{(lineItem.quantity * (lineItem.productDetail?.discountPercentage > 0
                                        ? (lineItem.productDetail.price - ((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100)).toFixed(2)
                                        : lineItem.productDetail.price)).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</strong>
                                </div>
                              </div>
                            </li>
                        )
                      })
                      }
                    </ul>
                    <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                      <div className="c-subtotal__left">Total Weight</div>
                      <div className="c-subtotal__right">{totalWeight.toLocaleString(navigator.language)} Grams</div>
                    </div>
                    {((district && district !== "" && calculateDeliveryAmount() > 0) || totalDiscount > 0) &&
                      <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                        <div className="c-subtotal__left">Total</div>
                        <div className="c-subtotal__right">Rs. {(subTotal + totalDiscount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                      </div>
                    }
                    {(district && district !== "" && calculateDeliveryAmount() > 0) &&
                      <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                        <div className="c-subtotal__left">Delivery Charges</div>
                        <div
                            className="c-subtotal__right">Rs. {calculateDeliveryAmount().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                      </div>
                    }
                    { totalDiscount > 0 &&
                      <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                        <div className="c-subtotal__left">Total Discount</div>
                        <div className="c-subtotal__right">Rs. {(totalDiscount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                      </div>
                    }
                    <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                      <div className="c-subtotal__left">Sub total</div>
                      <div className="c-subtotal__right">
                        Rs. {subTotalWithDeliveryCharge.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}
