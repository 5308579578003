import React, {useEffect} from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import {useShopify} from "../hooks"
import ProductCard from "../snippets/ProductCard";
import {Link} from 'react-router-dom';

export default (props) => {
  const {collectionProducts, fetchCollectionProducts} = useShopify()
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCollectionProducts('all')
  })
  return (
      <div>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>Wishlist</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <Link to="/" title="Home">Home</Link>
                <span aria-hidden="true">/</span>
                <span>Wishlist</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-wishlist">
          <div className="o-wrapper">
            <div className="js-filter-products">
              {collectionProducts && collectionProducts.length > 0 &&
              <React.Fragment>
                <div className="o-layout o-layout--custom c-collection__products">
                  {collectionProducts.map((product, i) => {
                    return (
                        <div className="o-layout__item o-layout__item--3" key={product.id}>
                          <ProductCard
                              key={product.id}
                              {...product}
                          />
                        </div>
                    )
                  })}
                </div>
              </React.Fragment>
              }
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  )
}