import {useDispatch, useSelector} from "react-redux"
import Client from "shopify-buy"
import {getAllCategories} from "../../../service/ProductCategoryService";
import {
  getAllProductDetailByProductId,
  getAllProducts,
  getProductByCategoryId,
  getProductById
} from "../../../service/ProductService";
import {SPAP_CHECKOUTID, SPAP_LOGGED_USER} from "../../../resources/Constant";

// Creates the client with Shopify-Buy and store info
//
const client = Client.buildClient({
  storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
  domain: "graphql.myshopify.com",
})

//
// Example Storefront
//
// const client = Client.buildClient({
//  storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
//  domain: "graphql.myshopify.com",
// })

const PRODUCTS_FOUND = "shopify/PRODUCTS_FOUND"
const PRODUCT_FOUND = "shopify/PRODUCT_FOUND"
const PRODUCT_DETAIL_FOUND = "shopify/PRODUCT_DETAIL_FOUND"
const COLLECTIONS_FOUND = "shopify/COLLECTIONS_FOUND"
const COLLECTION_FOUND = "shopify/COLLECTION_FOUND"
const COLLECTION_PRODUCTS_FOUND = "shopify/COLLECTION_PRODUCTS_FOUND"
const CHECKOUT_FOUND = "shopify/CHECKOUT_FOUND"
const SHOP_FOUND = "shopify/SHOP_FOUND"
const ADD_VARIANT_TO_CART = "shopify/ADD_VARIANT_TO_CART"
const UPDATE_QUANTITY_IN_CART = "shopify/UPDATE_QUANTITY_IN_CART"
const REMOVE_LINE_ITEM_IN_CART = "shopify/REMOVE_LINE_ITEM_IN_CART"
const OPEN_CART = "shopify/OPEN_CART"
const CLOSE_CART = "shopify/CLOSE_CART"
const CART_COUNT = "shopify/CART_COUNT"
const USER_FOUND = "shopify/USER_FOUND"

const initialState = {
  isCartOpen: false,
  cartCount: 0,
  checkout: new Map(),
  collections: [],
  collection: [],
  collectionProducts: [],
  products: [],
  product: {},
  shop: {},
  productDetails: [],
  loggedUser: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_FOUND:
      return {...state, products: action.payload}
    case PRODUCT_FOUND:
      return {...state, product: action.payload}
    case PRODUCT_DETAIL_FOUND:
      return {...state, productDetails: action.payload}
    case COLLECTIONS_FOUND:
      return {...state, collections: action.payload}
    case COLLECTION_FOUND:
      return {...state, collection: action.payload}
    case COLLECTION_PRODUCTS_FOUND:
      return {...state, collectionProducts: action.payload}
    case CHECKOUT_FOUND:
      return {...state, checkout: action.payload}
    case SHOP_FOUND:
      return {...state, shop: action.payload}
    case ADD_VARIANT_TO_CART:
      return {...state, checkout: action.payload}
    case UPDATE_QUANTITY_IN_CART:
      return {...state, checkout: action.payload}
    case REMOVE_LINE_ITEM_IN_CART:
      return {...state, checkout: action.payload}
    case OPEN_CART:
      return {...state, isCartOpen: true}
    case CLOSE_CART:
      return {...state, isCartOpen: false}
    case CART_COUNT:
      return {...state, cartCount: action.payload}
    case USER_FOUND:
      return {...state, loggedUser: action.payload}
    default:
      return state
  }
}

// Gets all the products from Shopify
function getProducts() {
  return (dispatch) => {
    // client.product.fetchAll(250).then((resp) => {
    //   dispatch({
    //     type: PRODUCTS_FOUND,
    //     payload: resp,
    //   })
    // })
    getAllProducts("name", 2500, 1).then((resp) => {
      dispatch({
        type: PRODUCTS_FOUND,
        payload: resp,
      })
    })
  }
}

// Gets individual item based on id
function getProduct(id) {
  // return async (dispatch) => {
  //   const resp = await client.product.fetchByHandle(id)
  //   dispatch({
  //     type: PRODUCT_FOUND,
  //     payload: resp,
  //   })
  //   return resp
  // }

  return async (dispatch) => {
    const resp = await getProductById(id)
    dispatch({
      type: PRODUCT_FOUND,
      payload: resp,
    })
    return resp
  }
}

// Gets product details based on product id
function getProductDetails(productId) {
  // return async (dispatch) => {
  //   const resp = await client.product.fetchByHandle(id)
  //   dispatch({
  //     type: PRODUCT_FOUND,
  //     payload: resp,
  //   })
  //   return resp
  // }

  return async (dispatch) => {
    const resp = await getAllProductDetailByProductId(productId)
    dispatch({
      type: PRODUCT_DETAIL_FOUND,
      payload: resp,
    })
    return resp
  }
}

// Gets all the collections from Shopify
function getCollections() {
  return (dispatch) => {
    // client.collection.fetchAll().then((resp) => {
    //   dispatch({
    //     type: COLLECTIONS_FOUND,
    //     payload: resp,
    //   })
    // })
    getAllCategories().then((resp) => {
      resp.sort(compare)
      dispatch({
        type: COLLECTIONS_FOUND,
        payload: resp,
      })
    })
  }
}

function compare( a, b ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}

// Gets all products from collection from Shopify
function getCollectionProducts(handle) {
  if (handle === 'all') {
    // return async (dispatch) => {
    //   const resp = await client.product.fetchAll(250)
    //   dispatch({
    //     type: COLLECTION_PRODUCTS_FOUND,
    //     payload: resp,
    //   })
    //   return resp

    return async (dispatch) => {
      const resp = await getAllProducts("name", 2500, 0)
      dispatch({
        type: COLLECTION_PRODUCTS_FOUND,
        payload: resp.content,
      })
      return resp
    }
  } else {
    // return async (dispatch) => {
    //   const resp = await client.collection.fetchByHandle(handle)
    //   dispatch({
    //     type: COLLECTION_PRODUCTS_FOUND,
    //     payload: resp.products,
    //   })
    //   return resp.products

    return async (dispatch) => {
      const resp = await getProductByCategoryId(handle)
      dispatch({
        type: COLLECTION_PRODUCTS_FOUND,
        payload: resp,
      })
      return resp
    }
  }
}

// Gets a  collection based on that collection's id
//
function getCollection(id) {
  return async (dispatch) => {
    const resp = await client.collection.fetch(id)
    dispatch({
      type: COLLECTION_FOUND,
      payload: resp,
    })
    return resp
  }
}

// Creates initial checkout state from Shopify
function checkout() {
  if (localStorage.getItem(SPAP_CHECKOUTID) !== '' && localStorage.getItem(SPAP_CHECKOUTID) !== null) {
    return (dispatch) => {
      dispatch({
        type: CHECKOUT_FOUND,
        payload: new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID))),
      })
    }
  } else {
    localStorage.setItem(SPAP_CHECKOUTID, JSON.stringify(Array.from(new Map())))
    return (dispatch) => {
      dispatch({
        type: CHECKOUT_FOUND,
        payload: new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID))),
      })
    }
  }
}

// Gets Shopify store information
function shopInfo() {
  return (dispatch) => {
    client.shop.fetchInfo().then((resp) => {
      dispatch({
        type: SHOP_FOUND,
        payload: resp,
      })
    })
  }
}

// Adds variants to cart/checkout
function addVariantToCart(lineItemsToAdd) {
  // return async (dispatch) => {
  //   const response = await client.checkout.addLineItems(
  //     checkoutId,
  //     lineItemsToAdd
  //   )
  //   dispatch({
  //     type: ADD_VARIANT_TO_CART,
  //     payload: response,
  //   })
  //   return response
  // }
  let map = new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID)))
  map.set(lineItemsToAdd.productDetail.productDetailId, lineItemsToAdd)
  localStorage.setItem(SPAP_CHECKOUTID, JSON.stringify(Array.from(map)))
  return async (dispatch) => {
    dispatch({
      type: ADD_VARIANT_TO_CART,
      payload: new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID))),
    })
    return {
      status: "success",
      data: "Successfully added to the cart",
    }
  }
}

// Updates quantity of line items in cart and in checkout state
function updateQuantityInCart(lineItemId, quantity, checkoutId) {
  const lineItemsToUpdate = [
    {id: lineItemId, quantity: parseInt(quantity, 10)},
  ]

  return async (dispatch) => {
    const resp = await client.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate
    )
    dispatch({
      type: UPDATE_QUANTITY_IN_CART,
      payload: resp,
    })
    return resp
  }
}

// Removes line item from cart and checkout state
function removeLineItemInCart(lineItemId) {

  let map = new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID)))
  map.delete(lineItemId)
  localStorage.setItem(SPAP_CHECKOUTID, JSON.stringify(Array.from(map)))
  return async (dispatch) => {
    dispatch({
      type: REMOVE_LINE_ITEM_IN_CART,
      payload: new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID))),
    })
    return {
      status: "success",
      data: "Successfully removed from the cart",
    }
  }
}

// To close the cart
function handleCartClose() {
  return {
    type: CLOSE_CART,
  }
}

// To open the cart
function handleCartOpen() {
  return {
    type: OPEN_CART,
  }
}

// Set the count of items in the cart
function handleSetCount(count) {
  return {
    type: CART_COUNT,
    payload: count,
  }
}

function handleEmptyCart() {

  localStorage.setItem(SPAP_CHECKOUTID, JSON.stringify(Array.from(new Map())))
  return (dispatch) => {
    dispatch({
      type: CHECKOUT_FOUND,
      payload: new Map(JSON.parse(localStorage.getItem(SPAP_CHECKOUTID))),
    })
  }
}


function handleGetUser() {
  if (localStorage.getItem(SPAP_LOGGED_USER) !== '' && localStorage.getItem(SPAP_LOGGED_USER) !== null) {
    return (dispatch) => {
      dispatch({
        type: USER_FOUND,
        payload: JSON.parse(localStorage.getItem(SPAP_LOGGED_USER)),
      })
      return JSON.parse(localStorage.getItem(SPAP_LOGGED_USER))
    }
  } else {
    return (dispatch) => {
      dispatch({
        type: USER_FOUND,
        payload: {},
      })
      return {}
    }
  }
}

function handleSetUser(loggedUser) {
  localStorage.setItem(SPAP_LOGGED_USER, JSON.stringify(loggedUser))
  return async (dispatch) => {
    dispatch({
      type: USER_FOUND,
      payload: loggedUser,
    })
    return {
      status: "success",
      data: "Successfully logged the user",
    }
  }
}


export function useShopify() {
  const dispatch = useDispatch()
  const cartStatus = useSelector((appState) => appState.shopifyState.isCartOpen)
  const cartCount = useSelector((appState) => appState.shopifyState.cartCount)
  const collections = useSelector((appState) => appState.shopifyState.collections)
  const collection = useSelector((appState) => appState.shopifyState.collection)
  const collectionProducts = useSelector((appState) => appState.shopifyState.collectionProducts)
  const products = useSelector((appState) => appState.shopifyState.products)
  const product = useSelector((appState) => appState.shopifyState.product)
  // const featured = useSelector((appState) => appState.shopifyState.featured)
  const productDetails = useSelector((appState) => appState.shopifyState.productDetails)
  const checkoutState = useSelector(
      (appState) => appState.shopifyState.checkout
  )
  const shopDetails = useSelector((appState) => appState.shopifyState.shop)
  const loggedUser = useSelector((appState) => appState.shopifyState.loggedUser)

  const fetchProducts = () => dispatch(getProducts())
  const fetchProduct = (id) => dispatch(getProduct(id))
  const fetchProductDetails = (id) => dispatch(getProductDetails(id))
  const fetchCollections = () => dispatch(getCollections())
  const fetchCollection = (id) => dispatch(getCollection(id))
  const fetchCollectionProducts = (id) => dispatch(getCollectionProducts(id))
  const createCheckout = () => dispatch(checkout())
  const createShop = () => dispatch(shopInfo())
  const closeCart = () => dispatch(handleCartClose())
  const openCart = () => dispatch(handleCartOpen())
  const setCount = (count) => dispatch(handleSetCount(count))
  const getUser = () => dispatch(handleGetUser())
  const setUser = (user) => dispatch(handleSetUser(user))

  const addVariant = (lineItemsToAdd) =>
      dispatch(addVariantToCart(lineItemsToAdd))
  const updateQuantity = (lineItemId, quantity, checkoutID) =>
      dispatch(updateQuantityInCart(lineItemId, quantity, checkoutID))
  const removeLineItem = (lineItemId) =>
      dispatch(removeLineItemInCart(lineItemId))
  const emptyCart = () =>
      dispatch(handleEmptyCart())

  return {
    collections,
    collection,
    collectionProducts,
    products,
    product,
    productDetails,
    cartStatus,
    checkoutState,
    cartCount,
    shopDetails,
    loggedUser,
    addVariant,
    fetchProducts,
    fetchProduct,
    fetchProductDetails,
    fetchCollections,
    fetchCollection,
    fetchCollectionProducts,
    createCheckout,
    createShop,
    closeCart,
    openCart,
    updateQuantity,
    removeLineItem,
    emptyCart,
    setCount,
    setUser,
    getUser
  }
}
