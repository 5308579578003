import React, {useEffect, useState} from "react"
import {useShopify} from "../hooks"
import Splide from '@splidejs/splide';
import 'lazysizes';
import ProductCard from "../snippets/ProductCard";

export default (props) => {
  const [loader, setLoader] = useState(false)
  const [sectionProducts, setSectionProducts] = useState([])
  const {fetchCollectionProducts} = useShopify()
  const id = props.collection_id
  useEffect(() => {
    fetchCollectionProducts(id).then((res) => {
      setSectionProducts(res.content)
      setLoader(true)
      var productsSlider = document.querySelectorAll('.js-products-slider');
      productsSlider.forEach((el, index) => {
        if (!el.classList.contains('is-active')) {
          new Splide(el, {
            perPage: 4,
            gap: '30px',
            drag: false,
            breakpoints: {
              600: {
                perPage: 2
              },
              991: {
                perPage: 3
              }
            }
          }).mount();
        }
      });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
      <React.Fragment>
        <section className={props.flash_sale === "true" ? "c-products c-products--flash-sale" : "c-products"}>
          <div className="o-wrapper">
            <div className="o-flex c-products__heading-countdown">
              <h3 className="c-products__heading">{props.title}</h3>
            {/*  {props.flash_sale == "true" &&*/}
            {/*    <React.Fragment>*/}
            {/*      <h3 className="c-products__countdown">*/}
            {/*        <div className="c-countdown">*/}
            {/*          <Countdown date={new Date(props.countdown)} />*/}
            {/*        </div>*/}
            {/*      </h3>*/}
            {/*      <h5 className="c-products__left">left</h5>*/}
            {/*    </React.Fragment>*/}
            {/*  }*/}
            </div>
            {sectionProducts && loader ? (
                <div className="splide c-products__splide js-products-slider">
                  <div className="splide__arrows">
                    <button className="splide__arrow--prev c-products__prev-slider">
                      <span className="e-icons e-icons--arrow-left"/>
                    </button>
                    <button className="splide__arrow--next c-products__next-slider">
                      <span className="e-icons e-icons--arrow-right"/>
                    </button>
                  </div>
                  <div className="splide__track">
                    <ul className="splide__list">
                      {sectionProducts.length > 0 && sectionProducts.map((product, i) => {
                        return (
                            <li className="splide__slide" key={product.productId}>
                              <ProductCard
                                  key={product.productId}
                                  {...product}
                              />
                            </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
            ) : (
                <React.Fragment>
                  <br/>
                  {loader ? 'No products.' : 'Loading...'}
                </React.Fragment>
            )}
          </div>
        </section>
      </React.Fragment>
  )
}