import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useShopify} from "../hooks"
import HomeProducts from "../components/HomeProducts"
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';

export default (props) => {
  const {
    product,
    productDetails,
    fetchProduct,
    fetchProductDetails,
    openCart,
    addVariant,
  } = useShopify()

  const id = props.match.params.productId
  const [size, setSize] = useState("")
  const [productDetail, setSelectedProductDetail] = useState("")
  const [quantity, setQuantity] = useState(1)
  const [activeTab, setActiveTab] = useState(1)
  const [activeImage, setActiveImage] = useState({src: '', index: ''})
  const [productDetailForSelectedSize, setProductDetailForSelectedSize] = useState([])

  const [productLoader, setProductLoader] = useState(false)
  const [productDetailLoader, setProductDetailLoader] = useState(false)

  // const [productDetails, setProductDetails] = useState([])


  function changeImage(src, index) {
    setActiveImage({'src': src, 'index': index})
  }

  function addToCart(event, product, productDetail, quantity) {
    let productForm = document.getElementById("productForm");
    if (productForm.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      openCart()
      // if (sizeId === "") {
      //   sizeId = defaultSize
      //   const lineItemsToAdd = [
      //     { variantId: sizeId, quantity: parseInt(quantity, 10) },
      //   ]
      //   const checkoutId = checkoutState.id
      //   localStorage.setItem("checkoutId", checkoutId);
      //   addVariant(checkoutId, lineItemsToAdd)
      // } else {
      //   const lineItemsToAdd = [
      //     { variantId: sizeId, quantity: parseInt(quantity, 10) },
      //   ]
      //   const checkoutId = checkoutState.id
      //   localStorage.setItem("checkoutId", checkoutId);
      //   addVariant(checkoutId, lineItemsToAdd)
      // }

      const lineItemsToAdd = {product: product, productDetail: productDetail, quantity: parseInt(quantity, 10)}
      // localStorage.setItem("checkoutId", checkoutId);
      addVariant(lineItemsToAdd)
      NotificationManager.success('Product added to your cart.', 'Success', 4000);
    }
  }

  function getUnique(arr, index) {

    const unique = arr
        .map(e => e[index])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    unique.sort((a, b) => (a.sizeScale > b.sizeScale) ? 1 : -1)
    return unique;
  }

  function getDistinctSizes(arr) {
    arr.sort((a, b) => (a.sizeScale > b.sizeScale) ? 1 : -1)
    let sizes = new Map();
    for (let productDetails of arr) {
      if (!sizes.has(productDetails.size)) {
        sizes.set(productDetails.size, {
          size: productDetails.size,
          desc: productDetails.sizeDesc,
          colour: [productDetails.colour],
          colourCode: [productDetails.colourCode],
          prices: [productDetails?.discountPercentage > 0
                ? (productDetails.price - ((productDetails.price * productDetails.discountPercentage)/100))
                : (productDetails.price)],
          sizeScale: productDetails.sizeScale,
        });
      } else {
        let size = sizes.get(productDetails.size);
        size.colour.push(productDetails.colour);
        size.colourCode.push(productDetails.colourCode);
        size.prices.push(productDetails?.discountPercentage > 0
            ? (productDetails.price - ((productDetails.price * productDetails.discountPercentage)/100))
            : (productDetails.price));
        sizes.set(productDetails.size, size);
      }
    }
    return Array.from(sizes.values());
  }

  function getDistinctColours(arr) {
    arr.sort((a, b) => (a.sizeScale > b.sizeScale) ? 1 : -1)
    let colours = new Map();
    for (let productDetails of arr) {
      if (!colours.has(productDetails.colour)) {
        colours.set(productDetails.colour, {
          colour: productDetails.colour,
          desc: productDetails.colourDesc,
          size: [productDetails.size],
          colourCode: productDetails.colourCode,
          prices: [productDetails?.discountPercentage > 0
              ? (productDetails.price - ((productDetails.price * productDetails.discountPercentage)/100))
              : (productDetails.price)],
        });
      } else {
        let colour = colours.get(productDetails.colour);
        colour.size.push(productDetails.size);
        colour.prices.push(productDetails?.discountPercentage > 0
            ? (productDetails.price - ((productDetails.price * productDetails.discountPercentage)/100))
            : (productDetails.price));
        colours.set(productDetails.colour, colour);
      }
    }
    return Array.from(colours.values());
  }

  function getColorsBySize(arr, size) {
    setSelectedProductDetail("")
    setProductDetailForSelectedSize([])
    setTimeout(() => {
      let productDetailForSize = [];
      for (let productDetail of arr) {
        if (size === productDetail.size) {
          productDetailForSize.push(productDetail);
        }
      }
      productDetailForSize.sort((a, b) => (a.sizeScale > b.sizeScale) ? 1 : -1)
      setProductDetailForSelectedSize(productDetailForSize)
    }, 250); //let to change the state

  }

  function setProductDetail(productDetails, productDetailId) {
    setSelectedProductDetail(null);
    for (let productDetail of productDetails) {
      if (productDetailId === productDetail.productDetailId) {
        setQuantity(productDetail.minSellingQty)
        setSelectedProductDetail(productDetail);
      }
    }
  }

  function validateQty(minSellingQty, packSize) {
    if (quantity < minSellingQty) {
      setQuantity(minSellingQty)
    } else {
      setQuantity(quantity % packSize > 0 ?
          (parseInt((quantity / packSize)) + 1) * packSize
          : quantity)
    }
  }

  function increaseQty(minSellingQty, packSize) {
    setQuantity(quantity < minSellingQty ? minSellingQty :
        (quantity % packSize > 0 ? (parseInt((quantity / packSize)) + 1) * packSize : ((quantity / packSize) + 1) * packSize));
  }

  function reduceQty(minSellingQty, packSize) {
    setQuantity(quantity <= minSellingQty ? minSellingQty :
        (quantity % packSize > 0 ? (parseInt((quantity / packSize)) - 1) * packSize : ((quantity / packSize) - 1) * packSize));
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setProductLoader(false)
    setProductDetailLoader(false)
    setSelectedProductDetail(null);
    fetchProduct(id).then((res) => {
      // changeImage(res.images[0].src, 0);
      changeImage(res.imageUrl, 0);
      setProductLoader(true)
    })

    fetchProductDetails(id).then((res) => {
      setProductDetailLoader(true)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h2>Product</h2>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>{product.name}</span>
              </nav>
            </div>
          </div>
        </div>

        <section className={productLoader && productDetailLoader ? 'c-product' : 'c-product c-product--loading'}>
          <div className="c-product-page" style={{minHeight: "400px"}}>
            <div className="c-filter__loading"></div>
            {productLoader && productDetailLoader &&
            <div className="o-wrapper">
              <div className="o-flex c-product-page__o-flex">
                <div className="c-product-page__left">
                  <div className="c-product-images o-flex">
                    <div className="c-product-images__left">
                      <ul className="c-product-images__thumbnails">
                        {/*{product.images.map((image, index) => {*/}
                        {/*  return (*/}
                        {/*    <li className={activeImage.index === index ? 'c-product-images__thumbnail c-product-images__thumbnail--active' : 'c-product-images__thumbnail'} key={index}><a href="#product_image" onClick={() => changeImage(image.src, index)}><img src={image.src} title={product.title} alt={product.title} /></a></li>*/}
                        {/*);*/}
                        {/*})}*/}
                        <li className={'c-product-images__thumbnail c-product-images__thumbnail--active'}>
                          <a
                              href="#product_image" onClick={() => changeImage(product.imageUrl, 0)}>
                            <img
                                src={product.imageUrl} title={product.name} alt={product.name}/>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="c-product-images__right">
                      {product?.maxDiscountPercentage > 0 &&
                        <div className="c-product-grid__tags"><div className="c-product-grid__sale">{product.maxDiscountPercentage}% Off on selected products<span>*</span></div><br /></div>
                      }
                      <div className="c-product-images__wishlist js-add-to-wishlist"
                           data-product-handle="drop-10-micro-structure-twill-suit">
                        <span
                            className="e-icons e-icons--heart"/>
                      </div>
                      <div className="c-product-images__image c-product-images__image--active">
                        <InnerImageZoom src={activeImage.src}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-product-page__right">
                  <h1 className="c-product-page__product-title">{product.name}</h1>
                  <div className="c-product-page__description">
                    <meta charSet="utf-8"/>
                    <span>{product.desc}</span>
                  </div>
                  <div className="c-product-page__form">
                    {productDetails && productDetails.length > 0 && product ?
                        <div id="product-variants" className="c-option">
                          <label htmlFor={"sizeOptions"}>Size</label>
                          <select
                              id="sizeOptions"
                              name={size}
                              onChange={(e) => {
                                getColorsBySize(productDetails, e.target.value)
                                setSize(e.target.value)
                              }}
                          >
                            {/*<option value="" disabled selected>Select a size</option>*/}
                            <option value="" defaultChecked={true}>Select a size</option>
                            {productDetails && productDetails.length > 0 && product &&
                            product.productId === productDetails[0].productId &&
                            getUnique(productDetails, 'size').map((productDetail) => {
                              return (
                                  <option
                                      value={productDetail.size}
                                      key={productDetail.size}
                                  >{productDetail.size}</option>
                              )
                            })}
                          </select>
                        </div>
                        : 'Loading...'}
                    {productDetailForSelectedSize && productDetailForSelectedSize.length > 0 &&
                    <div id="product-variants" className="c-option">
                      <label htmlFor={"colourOptions"}>Colour</label>
                      <select
                          id="colourOptions"
                          name={productDetail}
                          onChange={(e) => {
                            setProductDetail(productDetails, e.target.value)
                          }}
                      >
                        {/*<option value="" disabled selected>Select a colour</option>*/}
                        <option value="" defaultChecked={true}>Select a colour</option>
                        {productDetailForSelectedSize.map((productDetail) => {
                          return (
                              <option
                                  value={productDetail.productDetailId}
                                  key={productDetail.colour}
                              >{productDetail.colour}</option>
                          )
                        })}
                      </select>
                    </div>
                    }
                    {productDetail &&
                    <>
                      <div className="c-product-page__price">
                        <React.Fragment>
                          {productDetail?.discountPercentage > 0
                              ? <><s style={{color: "red"}}><span>Rs.{(productDetail.price).toFixed(2)}</span></s> <span style={{color: "green"}}>Rs.{(productDetail.price - ((productDetail.price * productDetail.discountPercentage)/100)).toFixed(2)}</span></>
                              : <span>Rs.{(productDetail.price).toFixed(2)}</span>
                          }
                          {/*<span>*/}
                          {/*  Rs. {productDetail.price.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</span>*/}
                        </React.Fragment>
                      </div>
                      <div className="c-product-page__add-to-cart">
                        <div className="c-product-grid__weight">Weight: {productDetail.weight} Grams</div>
                      </div>
                      {productDetail && productDetail.packSize && productDetail.packSize > 1 &&
                      <div className="c-product-page__add-to-cart o-flex">
                        <span
                            className="c-product-page__price--new">A packet contains with {productDetail.packSize}
                          pieces
                        </span>
                      </div>
                      }
                      {productDetail && productDetail.minSellingQty && productDetail.minSellingQty > 1 &&
                      <div className="c-product-page__add-to-cart o-flex">
                        <span
                            className="c-product-page__price--new">The minimum quantity per order
                          is {productDetail.minSellingQty} </span>
                      </div>
                      }
                      {quantity && productDetail && productDetail.qty >= productDetail.minSellingQty && quantity > productDetail.qty &&
                      <div className="c-product-page__add-to-cart o-flex">
                        <span className="c-product-page__price--new">Sorry!! Available stock is {productDetail.qty}.
                          Please select {productDetail.qty} or less for quantity.
                        </span>
                      </div>
                      }
                      {productDetail.qty < productDetail.minSellingQty &&
                      <div className="c-product-page__add-to-cart">
                        <div className="c-product-grid__out-of-stock">Out of stock</div>
                      </div>
                      }
                      {productDetail && productDetail.qty >= productDetail.minSellingQty &&
                      <form id="productForm">
                        <div className="c-product-page__add-to-cart o-flex">
                          <div className="c-quantity">
                            <div className="c-quantity__title">Quantity</div>
                            <div className="c-quantity__wrapper-input">
                              <span className="c-quantity__down js-quantity-down"
                                    style={{border: 0, background: "transparent"}}
                                    onClick={() => {
                                      reduceQty(productDetail.minSellingQty, productDetail.packSize)
                                    }}>
                                <svg width="4" height="2" viewBox="0 0 4 2" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0 1.63731H4V0.362305H0V1.63731Z" fill="#191919"></path>
                                </svg>
                              </span>
                              <input
                                  className="c-quantity__input"
                                  type="number"
                                  inputMode="numeric"
                                  min={productDetail.minSellingQty}
                                  value={quantity}
                                  onChange={(e) => {
                                    setQuantity(e.target.value)
                                  }}
                                  onBlur={() => {
                                    validateQty(productDetail.minSellingQty, productDetail.packSize)
                                  }}
                              ></input>
                              <span className="c-quantity__up js-quantity-up"
                                    style={{border: 0, background: "transparent"}}
                                    onClick={() => increaseQty(productDetail.minSellingQty, productDetail.packSize)}>
                                <svg width="8" height="10" viewBox="0 0 8 10" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 5.66002H8V4.34043H0V5.66002ZM3.29897 0.958984V9.04146H4.70103V0.958984H3.29897Z"
                                      fill="#191919"></path>
                                </svg>
                              </span>
                            </div>
                          </div>


                          <button type="submit" className="c-btn c-btn--add-to-cart c-btn--active"
                                  disabled={quantity && productDetail && quantity > productDetail.qty}
                                  onClick={(e) =>
                                      addToCart(e, product, productDetail, quantity)}>
                            <span>Add to cart</span>
                          </button>

                        </div>
                      </form>
                      }
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
          <hr/>
          <div className="o-wrapper">
            <ul className="c-product-tabs">
              <li className={activeTab === 1 ? 'c-product-tabs__item c-product-tabs__item--active' : 'c-product-tabs__item'}>
                <a href={() => false} onClick={(e) => {
                  setActiveTab(1)
                }}>Description
                </a>
              </li>
              <li className={activeTab === 2 ? 'c-product-tabs__item c-product-tabs__item--active' : 'c-product-tabs__item'}>
                <a href={() => false} onClick={(e) => {
                  setActiveTab(2)
                }}>Size chart
                </a>
              </li>
              <li className={activeTab === 3 ? 'c-product-tabs__item c-product-tabs__item--active' : 'c-product-tabs__item'}>
                <a href={() => false} onClick={(e) => {
                  setActiveTab(3)
                }}>Colour chart
                </a>
              </li>
            </ul>
          </div>
          <div
              className={activeTab === 1 ? 'c-product-description c-product-tab-item c-product-tab-item--active' : 'c-product-description c-product-tab-item'}>
            <div className="o-wrapper">
              <meta charSet="utf-8"/>
              <span>{product.desc}</span>
            </div>
          </div>
          <div
              className={activeTab === 2 ? 'c-product-description c-product-tab-item c-product-tab-item--active' : 'c-product-description c-product-tab-item'}>
            <div className="o-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Size</th>
                    <th>Size Description</th>
                    <th>Available Colours</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {productDetails && productDetails.length > 0 && getDistinctSizes(productDetails).map(productDetail => (
                      <tr key={productDetail.productDetailId}>
                        <td>{productDetail.size}</td>
                        <td>{productDetail.desc}</td>
                        <td>
                          {productDetail.colour.map((colour, index) => {
                            return (
                                <div>
                                  <div className="box"
                                       style={{"backgroundColor": productDetail.colourCode[index]}}></div>
                                  <div style={{"margin-bottom": "15px"}}>{colour}</div>
                                </div>
                            )
                          })}
                        </td>
                        <td>
                          {productDetail.prices.map((price, index) => {
                            return (
                                <div>
                                  <div style={{"margin-bottom": "15px"}}>
                                    Rs. {price.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                                </div>
                            )
                          })}
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
              className={activeTab === 3 ? 'c-product-description c-product-tab-item c-product-tab-item--active' : 'c-product-description c-product-tab-item'}>
            <div className="o-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Colour</th>
                    <th>Colour Description</th>
                    <th>Available Sizes</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {productDetails && productDetails.length > 0 && getDistinctColours(productDetails).map(productDetail => (
                      <tr key={productDetail.productDetailId}>
                        <td>
                          <div className="box" style={{"backgroundColor": productDetail.colourCode}}></div>
                          <div>{productDetail.colour}</div>
                        </td>
                        <td>{productDetail.desc}</td>
                        <td>
                          {productDetail.size.map((size, index) => {
                            return (
                                <div>
                                  <div style={{"margin-bottom": "15px"}}>{size}</div>
                                </div>
                            )
                          })}
                        </td>
                        <td>
                          {productDetail.prices.map((price, index) => {
                            return (
                                <div>
                                  <div style={{"margin-bottom": "15px"}}>
                                    Rs. {price.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                                </div>
                            )
                          })}
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <HomeProducts collection_id="all" title="Related Products"/>
        </section>
        }
        <Footer/>
      </React.Fragment>
  )
}