import React, {useEffect, useState} from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import {useShopify} from "../hooks"
import ProductCard from "../snippets/ProductCard";
import ProductList from "../snippets/ProductList";
import Filter from "../snippets/Filter";
import {Link} from 'react-router-dom';

export default (props) => {
  const {collections, collectionProducts, fetchCollectionProducts} = useShopify()
  const id = props.match.params.collectionId

  const [loader, setLoader] = useState(false)
  const [limit] = useState(12)
  const [pagination, setPagination] = useState(false)
  const [pages, setPages] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [productsView, setProductsView] = useState('grid');

  function handleView(view) {
    localStorage.setItem("productsView", view);
    setProductsView(view);
  }

  function handlePage(page) {
    window.scrollTo(0, 0);
    setCurrentPage(page)
  }

  useEffect(() => {
    if (localStorage.getItem('productsView') === 'list') {
      setProductsView('list')
    } else {
      setProductsView('grid')
    }

    if (loader === false) {
      window.scrollTo(0, 0);
    }

    setLoader(false)
    fetchCollectionProducts(id).then((res) => {
      setLoader(true)
      if (collectionProducts.length > limit) {
        setPagination(true);
        let page = []
        for (let i = 1; i <= parseInt(collectionProducts.length / limit) + 1; i++) {
          page.push(i)
        }
        setPages(page);
      } else {
        setPagination(false);
      }
    })

    const html = document.documentElement;
    var openFilter = document.querySelector('.js-open-mobile-widget');
    var closeFilter = document.querySelector('.js-close-mobile-widget');
    var mobileFilter = document.querySelector('.js-mobile-widget');

    if (openFilter) {
      openFilter.addEventListener('click', (event) => {
        mobileFilter.classList.add('c-collection__mobile-widget--active');
        html.style.overflow = 'hidden';
      });

      closeFilter.addEventListener('click', (event) => {
        mobileFilter.classList.remove('c-collection__mobile-widget--active');
        html.style.overflow = 'visible';
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
      <div>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              {id === 'all' ? (
                  <h1>Shop</h1>
              ) : (
                  <React.Fragment>
                    {collections.map((collection, i) => {
                      if (collection.categoryId === id) {
                        return (
                            <h1 key={i}>{collection.name}</h1>
                        )
                      }
                      return (<></>)
                    })}
                  </React.Fragment>
              )}
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <Link to="/" title="Home">Home</Link>
                <span aria-hidden="true">/</span>
                <span>Products</span>
              </nav>
            </div>
          </div>
        </div>
        <div className={loader ? 'c-collection js-collection' : 'c-collection js-collection c-collection--loading'}>
          <div className="o-wrapper">
            <div className="o-layout o-layout--custom">
              <div className="o-layout__item o-layout__item--3">
                <Filter/>
              </div>
              <div className="o-layout__item o-layout__item--9">
                <div className="c-collection__main-filter">
                  <div className="o-flex o-flex--align-items-center">
                    <div className="c-main-filter__open-mobile-widget js-open-mobile-widget">
                      <span className="e-icons e-icons--equalizer"/>
                    </div>
                    <div className="c-main-filter__views">
                      <ul className="o-flex o-flex--align-items-center">
                        <li className={productsView === 'list' ? '' : 'active'} onClick={() => handleView('grid')}>
                          <p>
                            <span className="e-icons e-icons--grid"/>
                          </p>
                        </li>
                        <li className={productsView === 'list' ? 'active' : ''} onClick={() => handleView('list')}>
                          <p>
                            <span className="e-icons e-icons--list"/>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="c-filter__loading"></div>
                <div className="js-filter-products">
                  {collectionProducts && collectionProducts.length > 0 ? (
                      <React.Fragment>
                        {productsView === 'list' ? (
                            <React.Fragment>
                              {collectionProducts.slice(((currentPage - 1) * limit), (currentPage * limit)).map((product, i) => {
                                return (
                                    <ProductList
                                        key={product.productId}
                                        {...product}
                                    />
                                )
                              })}
                            </React.Fragment>
                        ) : (
                            <div className="o-layout o-layout--custom c-collection__products">
                              {collectionProducts.slice(((currentPage - 1) * limit), (currentPage * limit)).map((product, i) => {
                                return (
                                    <div className="o-layout__item o-layout__item--4" key={product.productId}>
                                      <ProductCard
                                          key={product.productId}
                                          {...product}
                                      />
                                    </div>
                                )
                              })}
                            </div>
                        )}
                        {pagination &&
                        <div className="c-pagination">
                          {currentPage !== 1 &&
                          <span className="prev"
                                onClick={() => handlePage(currentPage - 1)}>« Previous</span>
                          }
                          {pages.map((page) => {
                            return (
                                <span className={currentPage === page ? 'page current' : 'page'} key={page}
                                      onClick={() => handlePage(page)}>{page}</span>
                            )
                          })}
                          {currentPage !== pages.length &&
                          <span className="next"
                                onClick={() => handlePage(currentPage + 1)}>Next »</span>
                          }
                        </div>
                        }
                      </React.Fragment>
                  ) : (
                      <React.Fragment>
                        <br/>
                        {loader ? 'No products.' : ''}
                      </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  )
}