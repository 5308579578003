import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import HomeProducts from "../components/HomeProducts"

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <div className="index">
          <Header/>
          <Slider/>
          {/*<HomeProducts collection_id="all" title="Flash sale" flash_sale="true" countdown="2021/09/28"/>*/}
          {/*<Banners/>*/}
          <HomeProducts collection_id="all" title="Products"/>
          <Footer/>
        </div>
      </React.Fragment>
  )
}