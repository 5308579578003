import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header homepage="true"/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>Not found</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>404 Not Found</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-page">
          <div className="o-wrapper">
            <p>The page you were looking for does not exist</p>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}