import {uploadImage} from "./ImageService";

const GLOBAL = require('../resources/Globals');

export const createProductCategory = async (productCategory) => {
  return await fetch(GLOBAL.URL_CREATE_PRODUCT_CATEGORY, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      categoryId: productCategory.categoryId,
      name: productCategory.name,
      desc: productCategory.desc,
    }),
  }).then(async (response) => {
    if (response.status === 201) {
      let responseJson = await response.json();
      return uploadImage('c_' + responseJson.data.categoryId, productCategory.image)
          .then(() => {
            return {
              status: "success",
              data: "product category saved",
            }
          })
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot persist product category",
    }
  });
}

export const getAllCategories = async () => {
  return await fetch(GLOBAL.URL_GET_ALL_PRODUCT_CATEGORY, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all categories",
    }
  });
}

export const getCategoryById = async (id) => {
  return await fetch(GLOBAL.URL_GET_BY_ID_PRODUCT_CATEGORY + id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get category by id",
    }
  });
}

export const productCategoryHeartBeat = async () => {
  return await fetch(GLOBAL.URL_HEARTBEAT_PRODUCT_CATEGORY, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data;
    }
  }).catch(error => {
    console.log("Product category service heartbeat is failing")
    return {
      status: "error",
      data: "Product category service heartbeat is failing",
    }
  });
}


