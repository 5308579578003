import React from 'react';
import {useEffect} from "react"
import {Link} from 'react-router-dom';
import Splide from '@splidejs/splide';
import 'lazysizes';

export default (props) => {
  useEffect(() => {
    var slider = document.querySelectorAll('.js-slider');
    var currentSlide = document.querySelector('.js-current-slide');

    slider.forEach((el, index) => {
      var splide = new Splide(el, {
        type: 'fade'
      }).mount();

      splide.on('moved', function () {
        currentSlide.textContent = splide.index + 1;
      });
    });
  })
  return (
      <section className="c-slider" style={{background: '#fff3e4'}}>
        <div className="splide c-slider__splide js-slider">
          <div className="splide__arrows">
            <button className="splide__arrow--prev c-slider__prev-slider">
              <span className="e-icons e-icons--arrow-left"/>
            </button>
            <button className="splide__arrow--next c-slider__next-slider">
              <span className="e-icons e-icons--arrow-right"/>
            </button>
          </div>
          <div className="splide__track">
            <ul className="splide__list">
              <li className="splide__slide" style={{backgroundColor: '#ffffff'}}>
                <div className="o-wrapper">
                  <div className="c-slider__item">
                    <div className="c-slider__first-image">
                      <img alt="Jackets & coats" data-sizes="auto" data-srcset="/images/slider-1-1.1.png 300w,
                      /images/slider-1-1.1.png 600w,
                      /images/slider-1-1.1.png 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </div>
                    <div className="c-slider__second-image">
                      <img alt="Jackets & coats" data-sizes="auto" data-srcset="/images/slider-1-2.1.png 300w,
                      /images/slider-1-2.1.png 600w,
                      /images/slider-1-2.1.png 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </div>
                    <div className="c-slider__text">
                      <h3 className="c-slider__subheading">We Provide <br/>The Total <br/>Printing & Packaging
                        <br/>Solution</h3>
                      <p className="c-slider__description">We, as a printing and packaging company goes an extra mile
                        and ensure <br/>the customer satisfaction as the printing partner.</p>
                      <br/>
                      <h5 className="c-slider__subheading">
                        <i className="fas fa-shipping-fast fa-flip-horizontal"></i> Island Wide Delivery</h5>
                      <Link to="/collections/all" className="c-slider__btn c-btn c-btn--black">Shop now
                        <span className="e-icons e-icons--arrow-right"/>
                      </Link>
                    </div>
                  </div>
                </div>
                {/*<div className="c-slider__text-right">Delivery Available</div>*/}
              </li>
              <li className="splide__slide" style={{backgroundColor: '#ffffff'}}>
                <div className="o-wrapper">
                  <div className="c-slider__item">
                    <div className="c-slider__first-image" style={{minWidth: '442px'}}>
                      <img alt="Lorem ipsum dolor sit" data-sizes="auto" data-srcset="/images/slider-2-1.2.png 300w,
                      /images/slider-2-1.2.png 600w,
                      /images/slider-2-1.2.png 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </div>
                    <div className="c-slider__second-image" style={{minWidth: '250px'}}>
                      <img alt="Lorem ipsum dolor sit" data-sizes="auto" data-srcset="/images/slider-2-2.1.png 300w,
                      /images/slider-2-2.1.png 600w,
                      /images/slider-2-2.1.png 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </div>
                    <div className="c-slider__text">
                      <h3 className="c-slider__subheading">We Provide <br/>The Total <br/>Printing & Packaging
                        <br/>Solution</h3>
                      <p className="c-slider__description">We, as a printing and packaging company goes an extra mile
                        and ensure <br/>the customer satisfaction as the printing partner.</p>
                      <br/>
                      <h5 className="c-slider__subheading">
                        <i className="fas fa-shipping-fast fa-flip-horizontal"></i> Island Wide Delivery</h5>
                      <Link to="/collections/all" className="c-slider__btn c-btn c-btn--black">Shop now
                        <span className="e-icons e-icons--arrow-right"/>
                      </Link>
                    </div>
                  </div>
                </div>
                {/*<div className="c-slider__text-right">Delivery Available</div>*/}
              </li>
            </ul>
          </div>
        </div>
        <div className="c-slider__counter">
          <div className="o-wrapper"><span className="js-current-slide">1</span>/2
          </div>
        </div>
      </section>
  )
}