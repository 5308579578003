import React from 'react';
import {Link} from 'react-router-dom';

export default (props) => {
  return (
      <div className="c-mobile-bottom-bar">
        <div className="o-flex o-flex--align-items-center">
          <div className="c-mobile-bottom-bar__item">
            <Link to="/home">
              <i className="e-icons e-icons--icon-home"></i>
              <span>Home</span>
            </Link>
          </div>
          {/*<div className="c-mobile-bottom-bar__item">*/}
          {/*  <Link to="/pages/wishlist">*/}
          {/*    <p className="c-header-wishlist__counter js-wishlist-counter"/>*/}
          {/*    <i className="e-icons e-icons--heart"></i>*/}
          {/*    <span>Wishlist</span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className="c-mobile-bottom-bar__item">
            <Link to="/account/login">
              <i aria-hidden="true" className="e-icons e-icons--icon-user"/>
              <span>Account</span>
            </Link>
          </div>
          <div className="c-mobile-bottom-bar__item">
            <div className="js-open-nav-sidebar">
              <i className="e-icons e-icons--icon-menu"></i>
              <span>Categories</span>
            </div>
          </div>
          {/*<div className="c-mobile-bottom-bar__item">*/}
          {/*  <div className="js-open-search">*/}
          {/*    <i className="e-icons e-icons--icon-magnifier"></i>*/}
          {/*    <span>Search</span>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
  )
}