import {createUser} from "./UserService";

const GLOBAL = require('../resources/Globals');


export const placeOrder = async (user, order) => {
  return await createUser(user).then((userResponse) => {
    if (userResponse.status === 'success') {
      return createOrder({
        status: 'NEW',
        amount: order.amount,
        discount: order.discount,
        userId: userResponse.data.userId,
        orderType: order.orderType,
        deliveryAmount: order.deliveryAmount,
        orderDetailDtoList: order.orderDetailDtoList,
      }).then(orderResponse => {
        return orderResponse;
      })
    } else {
      //TODO handle error
      return {
        status: "error",
        data: "Cannot persist Order",
      }
    }
  })
}

export const createOrder = async (order) => {
  return await fetch(GLOBAL.URL_CREATE_ORDER, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order),
  }).then(async (response) => {
    if (response.status === 201) {
      return await response.json();
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot persist Order",
    }
  });
}

export const findOrderById = async (orderid) => {
  return await fetch(GLOBAL.URL_GET_ORDER_BY_ID + orderid, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      return await response.json();
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot persist Order",
    }
  });
}

export const orderHeartBeat = async () => {
  return await fetch(GLOBAL.URL_HEARTBEAT_ORDER, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data;
    }
  }).catch(error => {
    console.log("Order service heartbeat is failing")
    return {
      status: "error",
      data: "Order service heartbeat is failing",
    }
  });
}