import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useShopify} from "../hooks"
import {NotificationManager} from 'react-notifications';

export default (props) => {
  const {checkoutState, removeLineItem} = useShopify()
  //const [counter, setCounter] = useState(0)
  const [loader, setLoader] = useState(false)


  function removeFromCart(lineItemId, e) {
    e.preventDefault()
    //const checkoutId = checkoutState.id
    removeLineItem(lineItemId)
    NotificationManager.success('Product removed from your cart.', 'Success', 4000);
    setLoader(true);
  }

  function calculateTotalDiscount() {
    let totalDiscount = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      let discountPrice = lineItem.productDetail?.discountPercentage > 0
          ? ((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100).toFixed(2)
          : 0
      totalDiscount += (lineItem.quantity * discountPrice)
      return totalDiscount;
    });
    return totalDiscount
  }
  function calculateSubTotal() {
    let subTotal = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      let price = lineItem.productDetail?.discountPercentage > 0
          ? (lineItem.productDetail.price - (((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100).toFixed(2)))
          : lineItem.productDetail.price
      subTotal += (lineItem.quantity * price)
      return subTotal;
    });
    return subTotal
  }

  function calculateTotalWeight() {
    let totalWeight = 0;
    Array.from(checkoutState.values()).map((lineItem) => {
      totalWeight += (lineItem.quantity * lineItem.productDetail.weight)
      return totalWeight;
    });
    return totalWeight
  }

  function closeSidebar() {
    let cartContent = document.querySelectorAll('.js-cart-content');
    let closeBackground = document.querySelector('.js-close-background');
    let header = document.querySelector('.js-header');
    cartContent.forEach((el, index) => {
      header.classList.remove("c-header--background")
      closeBackground.classList.remove("e-close-background--active");
      closeBackground.style.bottom = '0px';
      cartContent[index].classList.remove("c-header-cart__content--active");
    })
  }

  useEffect(() => {
    setLoader(false);
  }, [checkoutState])
  let counter = checkoutState.size;
  let subTotal = calculateSubTotal();
  let totalDiscount = calculateTotalDiscount();
  let totalWeight = calculateTotalWeight();
  return (

      <div className="c-header-cart">
        <div className="c-header-cart__icon js-open-cart">
          <i className="e-icons e-icons--icon-handbag"></i>
          <span className="c-header-cart__counter js-cart-counter">{counter}</span>
        </div>
        <div className="c-header-cart__content js-cart-content">
          <div className={loader === true ? 'is-loading' : ''}>
            <div className="c-header-cart__heading o-flex o-flex--align-items-center">
              <h5>Shopping Cart</h5>
              <p className="c-header-cart__close js-close-cart">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px"
                     height="14px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
                  <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                  L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                  c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                  l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                  L284.286,256.002z"/>
                </svg>
              </p>
            </div>
            <div className="js-ajax-cart-content">
              {counter === 0 ? (
                  <div className="c-header-cart__empty">
                    <p>Your bag is empty.</p>
                    <Link to="/collections/all" className="c-btn c-btn--black c-btn--start-shopping"
                          onClick={() => closeSidebar()}>
                      Start Shopping
                    </Link>
                  </div>
              ) : (
                  <div>
                    <ul className="c-header-cart__items">
                      {checkoutState && checkoutState.size > 0 &&
                      Array.from(checkoutState.values()).map((lineItem, index) => {
                        return (
                            <li className="o-flex o-flex--align-items-center" key={index}>
                              <div className="c-cart-product__image" style={{maxWidth: '25%'}}>
                                {lineItem.product.imageUrl ? (
                                    <Link to={'/product/' + lineItem.product.productId}
                                          onClick={() => closeSidebar()}>
                                      <img
                                          src={lineItem.product.imageUrl}
                                          alt={`{lineItem.product.name} product shot`}
                                      />
                                    </Link>
                                ) : null}
                              </div>
                              <div className="c-cart-product__right">
                                <div className="c-cart-product__name">
                                  <Link to={'/product/' + lineItem.product.productId}
                                        onClick={() => closeSidebar()}>{lineItem.product.name}
                                    <br/>
                                    {lineItem.productDetail.size} - {lineItem.productDetail.colour}
                                  </Link>
                                </div>
                                <div className="c-cart-product__price">Weight: {lineItem.quantity} x {(lineItem.productDetail.weight)}g
                                </div>
                                <div className="c-cart-product__price">{lineItem.quantity} x
                                  <strong>{lineItem.productDetail?.discountPercentage > 0
                                      ? <><s style={{color: "red"}}><span>Rs.{(lineItem.productDetail.price).toFixed(2)}</span></s> <span style={{color: "green"}}>Rs.{(lineItem.productDetail.price - ((lineItem.productDetail.price * lineItem.productDetail.discountPercentage)/100)).toFixed(2)}</span></>
                                      : <span>Rs.{(lineItem.productDetail.price).toFixed(2)}</span>
                                  }</strong>
                                </div>
                              </div>
                              <div className="c-cart-product__remove">
                                <a href="/#" onClick={(e) => {
                                  removeFromCart(lineItem.productDetail.productDetailId, e)
                                }}>
                                  <span className="e-icons e-icons--trash" style={{color: '#dd2831'}}/>
                                </a>
                              </div>
                            </li>
                        )
                      })
                      }
                    </ul>
                    <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                      <div className="c-subtotal__left">Total Weight</div>
                      <div className="c-subtotal__right">{totalWeight.toLocaleString(navigator.language)} Grams</div>
                    </div>
                    { totalDiscount > 0 &&
                        <>
                          <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                            <div className="c-subtotal__left">Total</div>
                            <div className="c-subtotal__right">Rs. {(subTotal + totalDiscount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                          </div>

                          <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                            <div className="c-subtotal__left">Total Discount</div>
                            <div className="c-subtotal__right">Rs. {(totalDiscount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                          </div>
                        </>
                    }
                    <div className="o-flex o-flex--align-items-center c-header-cart__subtotal">
                      <div className="c-subtotal__left">Sub Total</div>
                      <div className="c-subtotal__right">
                        Rs. {subTotal.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</div>
                    </div>
                    <div className="c-header-cart__checkout">
                      <Link to="/cart" onClick={() => closeSidebar()}>
                        <input type="submit"
                               name="checkout"
                               id="update-cart" className="c-btn c-btn--black c-btn--checkout" value="Checkout"/>
                      </Link>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}