import React from "react"
import {Link} from 'react-router-dom'
import {useShopify} from "../hooks"

export default (props) => {
  const {collections} = useShopify()
  return (
      <React.Fragment>
        <div className="c-collection__mobile-widget js-mobile-widget">
          <div className="c-mobile-widget__close js-close-mobile-widget">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.24" height="33.25" viewBox="0 0 33.24 33.25">
              <path id="x_copy_2" data-name="x copy 2" className="cls-1"
                    d="M2712.2,106.883l1.42,1.414-31.82,31.819-1.42-1.413Zm-30.4,0,31.82,31.82-1.42,1.413L2680.38,108.3Z"
                    transform="translate(-2680.38 -106.875)"/>
            </svg>
          </div>
          <div className="c-collection__categories">
            <h4 className="c-categories__heading">Categories</h4>
            <ul className="c-categories__list">
              {collections.map((collection, i) => {
                return (
                    <li key={i}>
                      <Link className="field" to={'/collections/' + collection.categoryId}>
                        {collection.name}
                      </Link>
                    </li>
                )
              })}
            </ul>
          </div>
          {/*<div className="c-collection__filters js-filter">*/}
          {/*  <div className="c-filter">*/}
          {/*    <div className="c-filter__heading">Brands</div>*/}
          {/*    <div className="c-filter__content">*/}
          {/*      <ul>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_1" className="js-filter-input" type="checkbox" name="tag" defaultValue="alexandermer" />*/}
          {/*          <label htmlFor="filter_1_1" className="label-text">Alexandermer</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_2" className="js-filter-input" type="checkbox" name="tag" defaultValue="bossess" />*/}
          {/*          <label htmlFor="filter_1_2" className="label-text">Bossess</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_3" className="js-filter-input" type="checkbox" name="tag" defaultValue="burbered" />*/}
          {/*          <label htmlFor="filter_1_3" className="label-text">Burbered</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_4" className="js-filter-input" type="checkbox" name="tag" defaultValue="hermeck" />*/}
          {/*          <label htmlFor="filter_1_4" className="label-text">Hermeck</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_5" className="js-filter-input" type="checkbox" name="tag" defaultValue="hoga" />*/}
          {/*          <label htmlFor="filter_1_5" className="label-text">Hoga</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_1_6" className="js-filter-input" type="checkbox" name="tag" defaultValue="toomya" />*/}
          {/*          <label htmlFor="filter_1_6" className="label-text">Toomya</label>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="c-filter">*/}
          {/*    <div className="c-filter__heading">Color</div>*/}
          {/*    <div className="c-filter__content">*/}
          {/*      <ul>*/}
          {/*        <li>*/}
          {/*          <input id="filter_2_1" className="js-filter-input" type="checkbox" name="tag" defaultValue="black" />*/}
          {/*          <label htmlFor="filter_2_1" className="label-text">Black</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_2_2" className="js-filter-input" type="checkbox" name="tag" defaultValue="red" />*/}
          {/*          <label htmlFor="filter_2_2" className="label-text">Red</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_2_3" className="js-filter-input" type="checkbox" name="tag" defaultValue="purple" />*/}
          {/*          <label htmlFor="filter_2_3" className="label-text">Purple</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_2_4" className="js-filter-input" type="checkbox" name="tag" defaultValue="white" />*/}
          {/*          <label htmlFor="filter_2_4" className="label-text">White</label>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="c-filter">*/}
          {/*    <div className="c-filter__heading">Price</div>*/}
          {/*    <div className="c-filter__content">*/}
          {/*      <ul>*/}
          {/*        <li>*/}
          {/*          <input id="filter_3_1" className="js-filter-input" type="checkbox" name="tag" defaultValue="0-100" />*/}
          {/*          <label htmlFor="filter_3_1" className="label-text">$0 - $100</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_3_2" className="js-filter-input" type="checkbox" name="tag" defaultValue="100-200" />*/}
          {/*          <label htmlFor="filter_3_2" className="label-text">$100 - $200</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_3_3" className="js-filter-input" type="checkbox" name="tag" defaultValue="200-500" />*/}
          {/*          <label htmlFor="filter_3_3" className="label-text">$200 - $500</label>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <input id="filter_3_4" className="js-filter-input" type="checkbox" name="tag" defaultValue="500-1000" />*/}
          {/*          <label htmlFor="filter_3_4" className="label-text">$500 - $1000</label>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </React.Fragment>
  )
}