import React from 'react';
import {Link} from 'react-router-dom';
import {useShopify} from "../redux/ducks/shopify";
import {ADDRESS, EMAIL, PROVINCE_COUNTRY, TEL_LAND, TEL_MOBILE} from "../resources/Client";


export default (props) => {
  const {collections} = useShopify()

  function closeSidebar() {
    let navSidebar = document.querySelector('.js-nav-sidebar');
    let closeBackground = document.querySelector('.js-close-background');
    let header = document.querySelector('.js-header');
    navSidebar.classList.remove('c-header-nav-sidebar__content--active');
    closeBackground.classList.remove('e-close-background--active');
    closeBackground.classList.remove('e-close-background--nav-sidebar');
    header.classList.remove('c-header--nav-sidebar');
  }

  return (
      <div className="c-header-nav-sidebar__content js-nav-sidebar">
        <div className="c-nav-sidebar__close js-close-nav-sidebar">
          <svg xmlns="http://www.w3.org/2000/svg" width="33.24" height="33.25" viewBox="0 0 33.24 33.25">
            <path id="x_copy_2" data-name="x copy 2" className="cls-1"
                  d="M2712.2,106.883l1.42,1.414-31.82,31.819-1.42-1.413Zm-30.4,0,31.82,31.82-1.42,1.413L2680.38,108.3Z"
                  transform="translate(-2680.38 -106.875)"/>
          </svg>
        </div>
        <ul className="c-nav-sidebar">
          {collections.map((collection, i) => {
            return (
                <li key={i}>
                  <Link className="field" to={'/collections/' + collection.categoryId} onClick={() => {
                    closeSidebar()
                  }}>
                    {collection.name}
                  </Link>
                </li>
            )
          })}
        </ul>
        <ul className="c-nav-sidebar__contact">
          <li>
            <span style={{color: '#272525'}}>GET IN TOUCH WITH US:</span>
          </li>
          <li>
            <span style={{color: '#e52817'}}>{TEL_LAND}, {TEL_MOBILE}</span>
          </li>
          <li>{EMAIL}</li>
          <li>{ADDRESS}</li>
          <li>{PROVINCE_COUNTRY}</li>
        </ul>
      </div>
  )
}