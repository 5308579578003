import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from 'react-router-dom';

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h2 style={{fontSize: '20px'}}>News</h2>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <Link to="/" title="Home">Home</Link>
                <span aria-hidden="true">/</span>
                <Link to="/blogs/news" title>News</Link>
                <span aria-hidden="true">/</span>
                <span>How people will look like in 10 years time</span>
              </nav>
            </div>
            <h1 style={{paddingTop: '25px'}}>How people will look like in 10 years time</h1>
          </div>
        </div>
        <div className="c-page">
          <div className="o-wrapper">
            <div className="c-article__image">
              <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-88.jpg 300w,
              /images/blog-88.jpg 600w,
              /images/blog-88.jpg 900w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                   className="lazyload"/>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
              rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
              explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
              consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
              dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
              incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
              exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui
              dolorem eum fugiat quo voluptas nulla pariatur?
            </p>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}