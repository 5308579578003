export const COMPANY_NAME = 'Smart Printing & Packaging';

export const ADDRESS = 'No. 217/6 A, Main Street, Moratuwa,';

export const PROVINCE_COUNTRY = 'Western Province, Sri Lanka.';

export const TEL_LAND = '+94 112 647 057';

export const TEL_MOBILE = '+94 741 023 822';

export const EMAIL = 'info@smartprinting.lk'

