import React from 'react';
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import store from "./redux/store"
import App from "./components/App"
import "./styles/main.scss"
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';


ReactDOM.render(
    <Provider store={store}>
      <App/>
    </Provider>,
    document.getElementById("root")
)
