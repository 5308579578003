import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {EMAIL, TEL_LAND, TEL_MOBILE} from "../resources/Client";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>Contact Us</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>Contact Us</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-contact">
          <div className="o-wrapper">
            <div className="c-contact__map">
              <iframe frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d933.2791266433471!2d79.88318316048469!3d6.77574689615928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae245e21aacd821%3A0xd58027998ca75b46!2sSmart%20Printing%20and%20Packaging!5e0!3m2!1sen!2slk!4v1610254150729!5m2!1sen!2slk"
                      title="Smart Printing and Packaging" aria-label="Smart Printing and Packaging"></iframe>
            </div>
            <div className="c-contact__items">
              <div className="o-layout o-layout--custom">
                <div className="o-layout__item o-layout__item--2">
                  <h6 className="c-contact-item__heading">Store Address</h6>
                  <div className="c-contact-item__content">No. 217/6 A,
                    <br/>
                    Main Street,
                    <br/>
                    Moratuwa,
                    <br/>
                    Western Province,
                    <br/>
                    Sri Lanka.
                  </div>
                </div>
                <div className="o-layout__item o-layout__item--4">
                  <h6 className="c-contact-item__heading">Email</h6>
                  <div className="c-contact-item__content">
                    <a href="mailto:info@smartprinting.lk">{EMAIL}</a>
                  </div>
                </div>
                <div className="o-layout__item o-layout__item--3">
                  <h6 className="c-contact-item__heading">Hotline</h6>
                  <div className="c-contact-item__content">{TEL_LAND}
                    <br/>
                    {TEL_MOBILE}
                  </div>
                </div>
                <div className="o-layout__item o-layout__item--3">
                  <h6 className="c-contact-item__heading">Social</h6>
                  <div className="c-contact-item__content">
                    <a
                        href="https://www.facebook.com/Smart-Printing-Packaging-111982957193183/" rel="noopener noreferrer"
                        target="_blank">Facebook
                    </a>
                    <br/>
                    {/*<a href="http://instagram.com" target="_blank">Instagram</a>*/}
                  </div>
                </div>
              </div>
            </div>
            {/*<form id="contact_form" className="contact-form">*/}
            {/*  <h5>Have Question?</h5>*/}
            {/*  <div className="o-layout o-layout--custom">*/}
            {/*    <div className="o-layout__item o-layout__item--4">*/}
            {/*      <input type="text" id="ContactFormName" name="contact[name]" placeholder="Name"/>*/}
            {/*    </div>*/}
            {/*    <div className="o-layout__item o-layout__item--4">*/}
            {/*      <input type="email" id="ContactFormEmail" name="contact[email]" placeholder="E-mail"/>*/}
            {/*    </div>*/}
            {/*    <div className="o-layout__item o-layout__item--4">*/}
            {/*      <input type="tel" id="ContactFormPhone" name="contact[phone]" placeholder="Phone"/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <textarea rows={10} id="ContactFormMessage" name="contact[body]" placeholder="Message"/>*/}
            {/*  <input type="submit" className="c-btn c-btn--black" value="Send"/>*/}
            {/*</form>*/}
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}