import {uploadImage} from "./ImageService";

const GLOBAL = require('../resources/Globals');

export const createProduct = async (product) => {
  return await fetch(GLOBAL.URL_CREATE_PRODUCT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      productId: product.productId,
      name: product.name,
      categoryId: product.categoryId,
      desc: product.desc,
      status: 'A',
      productDetails: product.productDetails
    }),
  }).then(async (response) => {
    if (response.status === 201) {
      let responseJson = await response.json();
      return uploadImage('p_' + responseJson.data.productId, product.image)
          .then(async (value) => {
            return {
              status: "success",
              data: "product saved",
            }
          })
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot persist product",
    }
  });
}

export const getAllProducts = async (orderBy, pageSize, page) => {
  return await fetch(GLOBAL.URL_GET_ALL_PRODUCT, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      orderby: orderBy,
      "page-size": pageSize,
      page: page
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all products",
    }
  });
}

export const deleteProductByProductId = async (productId) => {
  return await fetch(GLOBAL.URL_DELETE_PRODUCT_BY_PRODUCT_ID + productId, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.status
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all product details by product id",
    }
  });
}

export const getProductById = async (productId) => {
  return await fetch(GLOBAL.URL_GET_PRODUCT_BY_ID + productId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all product details by product id",
    }
  });
}


export const getProductByCategoryId = async (categoryId) => {
  return await fetch(GLOBAL.URL_GET_PRODUCT_BY_CATEGORY_ID + categoryId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all product details by product id",
    }
  });
}


export const getAllProductDetailByProductId = async (productId) => {
  return await fetch(GLOBAL.URL_GET_PRODUCT_DETAIL_BY_PRODUCT_ID + productId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data.filter(productDetail => !productDetail?.status || productDetail?.status === 'A')
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all product details by product id",
    }
  });
}


export const getAllProductDetailByProductDetailId = async (productDetailId) => {
  return await fetch(GLOBAL.URL_GET_PRODUCT_DETAIL_BY_PRODUCT_DETAIL_ID + productDetailId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get all product details by product detail id",
    }
  });
}

export const productHeartBeat = async () => {
  return await fetch(GLOBAL.URL_HEARTBEAT_PRODUCT, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data;
    }
  }).catch(error => {
    console.log("Product service heartbeat is failing")
    return {
      status: "error",
      data: "Product service heartbeat is failing",
    }
  });
}

