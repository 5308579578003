const GLOBAL = require('../resources/Globals');

export const createUser = async (user) => {
  return await fetch(GLOBAL.URL_CREATE_USER, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  }).then(async (response) => {
    if (response.status === 201) {
      return await response.json();
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot persist User",
    }
  });
}

export const findUserById = async (userid) => {
  return await fetch(GLOBAL.URL_GET_USER_BY_ID + userid, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data
    }
  }).catch(error => {
    return {
      status: "error",
      data: "Cannot get User",
    }
  });
}

export const userHeartBeat = async () => {
  return await fetch(GLOBAL.URL_HEARTBEAT_USER, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson.data;
    }
  }).catch(error => {
    console.log("User service heartbeat is failing")
    return {
      status: "error",
      data: "User service heartbeat is failing",
    }
  });
}
