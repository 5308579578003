import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import {findOrderById} from "../service/OrderService";
import {getAllProductDetailByProductDetailId, getProductById} from "../service/ProductService";
import {findUserById} from "../service/UserService";
import {ADDRESS, COMPANY_NAME, PROVINCE_COUNTRY, TEL_LAND, TEL_MOBILE} from "../resources/Client";
import moment from 'moment';
import Footer from "../components/Footer";

export default (props) => {
  const id = props.match.params.orderId

  const [order, setOrder] = useState({});
  const [productDetailMap, setProductDetailMap] = useState(new Map());
  const [productMap, setProductMap] = useState(new Map());
  const [userInfo, setUserInfo] = useState({})

  function printInvoice(e) {
    let content = document.getElementById("invoice-div");
    let pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    findOrderById(id).then((response) => {

      let productDetails = new Map()
      let products = new Map()
      response.data.orderDetailDtoList.map((orderDetail) => {
        getAllProductDetailByProductDetailId(orderDetail.productDetailId).then((productDetailResponse) => {
          //return productDetailResponse.data
          productDetails.set(orderDetail.productDetailId, productDetailResponse)
          getProductById(productDetailResponse.productId).then((productResponse) => {
            products.set(orderDetail.productDetailId, productResponse);
            return products;
          })
          return productDetails;
        })
        return orderDetail;
      })

      findUserById(response.data.userId).then((userResponse) => {
        setUserInfo(userResponse)
      })

      setProductDetailMap(productDetails)
      setProductMap(products)

      setTimeout(() => {
        setOrder(response.data)
      }, 1000); //let to change the state

    });

    let scale = 'scale(1)';

    let width = window.innerWidth;
    let maxWidth = 680;
    if (width >= maxWidth) {
      document.getElementById("invoice-container").style.webkitTransform = scale;
    } else {
      scale = Math.min(360 / 680, 1);
      document.getElementById("invoice-container").style.webkitTransform = 'scale(' + scale + ')';
    }


    // document.getElementById("invoice-container").style.transition = scale;


  }, [id])


  const style = {
    tableStyle: {
      "fontSize": "12px",
      "marginTop": "2px",
      "borderRadius": "2px",
      "border": "0px solid #b7b6b6"
    },
    trStyle: {
      "border": "1px solid #b7b6b6",
      "padding": "10px"
    },
    trAlignRightStyle: {
      "border": "1px solid #b7b6b6",
      "padding": "10px",
      "textAlign": "right!important",
    },
    trAlignRightStyle2: {
      "border": "1px solid #b7b6b6",
      "padding": "10px",
      "textAlign": "right",
    },
    trAlignCenterStyle: {
      "border": "1px solid #b7b6b6",
      "textAlign": "center",
      "padding": "5px"
    },
    trEmptyBorderStyle: {
      "border": "0px solid #b7b6b6",
    },
    trDoubleStyle: {
      "border": "1px solid #b7b6b6",
      "border-bottom": "double",
      "textAlign": "right",
      "padding": "10px",
      "fontWeight": "bold"
    }
  };

  return (
      <React.Fragment>
        <Header homepage="true"/>
        <div id="invoice-container" style={{transformOrigin: "top left"}}>
          <div
              className={order && order.orderDetailDtoList && productDetailMap && order.orderDetailDtoList.length === productDetailMap.size && productMap.size > 0 && userInfo && userInfo.firstName ? 'c-login' : 'c-login c-login--loading'}>
            {order && order.orderDetailDtoList && productDetailMap &&
            order.orderDetailDtoList.length === productDetailMap.size && productMap.size > 0 &&
            userInfo && userInfo.firstName &&
            <>
              <div style={{
                "width": "680px",
                "marginRight": "auto",
                "marginLeft": "auto",
                "maxWidth": "1370px",
                "textAlign": "right"
              }}>
                <button onClick={(e) => printInvoice(e)} className="c-btn c-btn--black"
                        style={{marginBottom: "10px", marginRight: "10px"}}>
                  <i className="fa fa-print" aria-hidden="true"></i> Print
                </button>
              </div>

              <div id="invoice-div" style={{
                "width": "680px",
                "marginRight": "auto",
                "marginLeft": "auto",
                "maxWidth": "1370px",
                "border": "1px solid #e2e2e2"
              }}>
                <div className="c-filter__loading"></div>
                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div width="50%" style={{margin: "15px"}}>
                    <div className="js-login-box">
                      <a href="https://www.smartprintingandpackaging.com" target="_blank" rel="noopener noreferrer"
                         data-saferedirecturl="https://www.smartprintingandpackaging.com">
                        <img
                            src="https://spap-images-dev.s3.ap-south-1.amazonaws.com/spap-mail-logo.png"
                            width="300px" alt="Smart Printing & Packaging" className="CToWUd"/>
                      </a>
                      <h5 style={{
                        "margin": "0px",
                        "textTransform": "uppercase",
                        "color": "#808080",
                        "marginTop": "10px"
                      }}>INVOICE
                      </h5>
                    </div>
                  </div>
                  <div width="50%" style={{
                    margin: "15px",
                    "textAlign": "right",
                    "fontWeight": "bold",
                    "fontSize": "16px",
                    "verticalAlign": "bottom!important",
                    "border": "0px #ffffff !important",
                    alignSelf: "flex-end"
                  }}>
                    <div className="js-login-box">
                      <span className="" style={{"color": "green"}}>ORDER PLACED SUCCESSFULLY</span>
                      <br/>
                      <span style={{"fontSize": "16px"}}>Order No : {order.invoiceNo}</span>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div width="50%" style={{margin: "15px"}}>
                    <div className="js-login-box">
                      <span style={{"fontSize": "14px", "fontWeight": "bold", "textTransform": "uppercase"}}>CUSTOMER
                        DETAILS
                      </span>
                      <p style={{"lineHeight": "20px", "marginTop": "5px"}}>
                        {userInfo.firstName} {userInfo.lastName}
                        <br/>
                        {userInfo.telephone}
                        <br/>
                        <a href="mailto:$userDto.email" target="_blank" rel="noopener noreferrer">{userInfo.email}</a>
                      </p>
                    </div>
                  </div>
                  <div width="50%" style={{
                    margin: "15px",
                    "textAlign": "right",
                    "verticalAlign": "bottom!important",
                    "border": "0px #ffffff !important"
                  }}>
                    <div className="js-login-box">
                      {order.orderType === "pickup" ?
                          <span style={{
                            "fontSize": "14px",
                            "fontWeight": "bold",
                            "float": "right"
                          }}>PICKUP LOCATION</span>
                          : <span style={{"fontSize": "14px", "fontWeight": "bold", "float": "right"}}>DELIVERY ADDRESS
                            DETAILS</span>}
                      <br/>
                      <p style={{"lineHeight": "20px", "marginTop": "5px", "width": "250px", "float": "right"}}>
                        {order.orderType === "pickup" ?
                            <>
                              {COMPANY_NAME}
                              <br/>
                              {ADDRESS}
                              <br/>
                              {PROVINCE_COUNTRY}
                              <br/>
                              {TEL_LAND}, {TEL_MOBILE}
                            </> :
                            <>{userInfo.firstName} {userInfo.lastName}
                              <br/>
                              {userInfo.address}
                              <br/>
                              {userInfo.telephone}</>
                        }

                      </p>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div width="50%" style={{margin: "15px"}}>
                    <div className="js-login-box">
                      <span style={{"fontSize": "14px", "fontWeight": "bolder"}}>ORDER TYPE</span>
                      <p style={{"marginTop": "5px", "textTransform": "capitalize"}}>
                        {order.orderType}
                      </p>
                    </div>
                  </div>
                  <div width="50%" style={{
                    margin: "15px",
                    "textAlign": "right",
                    "verticalAlign": "bottom!important",
                    "border": "0px #ffffff !important"
                  }}>
                    <div className="js-login-box">
                      <span style={{"fontSize": "14px", "fontWeight": "bolder"}}>TRANSACTION NO</span>
                      <p style={{"marginTop": "5px"}}>
                        Not Applicable
                      </p>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div width="50%" style={{margin: "15px"}}>
                    <div className="js-login-box">
                      <span style={{"fontSize": "14px", "fontWeight": "bolder"}}>PAYMENT METHOD | TYPE</span>
                      <p style={{"marginTop": "5px"}}>
                        {order.orderType === "pickup" ?
                            <>Pay On Pickup | Cash</> :
                            <>Pay On Delivery | Cash</>
                        }
                      </p>
                    </div>
                  </div>
                  <div width="50%" style={{
                    margin: "15px",
                    "textAlign": "right",
                    "verticalAlign": "bottom!important",
                    "border": "0px #ffffff !important"
                  }}>
                    <div className="js-login-box">
                      <span style={{"fontSize": "14px", "fontWeight": "bolder"}}>INVOICE DATE TIME</span>
                      <p style={{"marginTop": "5px"}}>
                        {moment(order.createdAt).format("ddd, DD MMM YYYY HH:mm:ss")}
                      </p>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div style={{
                    margin: "15px",
                    width: "-webkit-fill-available",
                  }} className="js-login-box">
                    <span style={{"fontSize": "14px", "fontWeight": "bolder"}}>YOUR ORDER</span>
                    <div>
                      <table width="100%"
                             style={style.tableStyle}>
                        <thead>
                        <tr>

                          <th width="100px"
                              style={style.trStyle}>
                            Product
                          </th>

                          <th style={style.trStyle}>
                            Description
                          </th>
                          <th width="85px"
                              style={style.trAlignRightStyle}>
                            Unit Price
                          </th>
                          <th width="85px"
                              style={style.trAlignRightStyle}>
                            Qty
                          </th>
                          <th width="90px"
                              style={style.trAlignRightStyle}>
                            Amount
                          </th>
                        </tr>
                        </thead>
                        <tbody style={{fontSize: "14px"}}>
                        {order.orderDetailDtoList.map(orderDetailDto => (
                            <tr>
                              <td style={style.trAlignCenterStyle}>
                                <img width="90px" alt={productMap.get(orderDetailDto.productDetailId).name}
                                     src={productMap.get(orderDetailDto.productDetailId).imageUrl}/>
                              </td>
                              <td style={style.trStyle}>
                                {productMap.get(orderDetailDto.productDetailId).name}
                                <br/>
                                Size: {productDetailMap.get(orderDetailDto.productDetailId).size}
                                <br/>
                                Colour: {productDetailMap.get(orderDetailDto.productDetailId).colour}
                              </td>
                              <td style={style.trAlignRightStyle}>
                                <div style={{"textAlign": "end"}}>
                                  {orderDetailDto.originalPrice > orderDetailDto.price ?
                                      <><s>{orderDetailDto.originalPrice.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</s> {orderDetailDto.price.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</>
                                      : <>{orderDetailDto.price.toLocaleString(navigator.language, {minimumFractionDigits: 2})}</>
                                  }
                                </div>
                              </td>
                              <td style={style.trAlignRightStyle}>
                                <div style={{"textAlign": "end"}}>
                                  {orderDetailDto.qty}
                                </div>
                              </td>

                              <td style={style.trAlignRightStyle}>
                                <div style={{"textAlign": "end"}}>
                                  {(orderDetailDto.price * orderDetailDto.qty).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                </div>
                              </td>
                            </tr>
                        ))}
                        {((order.orderType === "delivery" && order.deliveryAmount) || order.discount > 0) &&
                            <>
                              <tr>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trAlignRightStyle}>
                                  <div style={{"textAlign": "end"}}>
                                    <b>Gross Amount</b>
                                  </div>
                                </td>
                                <td style={style.trAlignRightStyle}>
                                  <div style={{"textAlign": "end"}}>
                                    {(order.amount + order.discount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                  </div>
                                </td>
                              </tr>
                            </>
                        }
                        {(order.discount > 0) &&
                            <>
                              <tr>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trAlignRightStyle}>
                                  <div style={{"textAlign": "end"}}>
                                    <b>Total Discount</b>
                                  </div>
                                </td>
                                <td style={style.trAlignRightStyle}>
                                  <div style={{"textAlign": "end"}}>
                                    {order.discount.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                  </div>
                                </td>
                              </tr>
                            </>
                        }
                        {(order.orderType === "delivery" && order.deliveryAmount) ?
                            <>
                              <tr>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trAlignRightStyle2}>
                                  <b>Delivery Charges</b>
                                </td>
                                <td style={style.trAlignRightStyle2}>
                                  {order.deliveryAmount.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                </td>
                              </tr>
                              <tr>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trDoubleStyle}>
                                  <b>Total Amount</b>
                                </td>
                                <td style={style.trDoubleStyle}>
                                  {(order.amount + order.deliveryAmount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <tr>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trEmptyBorderStyle}></td>
                                <td style={style.trDoubleStyle}>
                                  <b>Total Amount</b>
                                </td>
                                <td style={style.trDoubleStyle}>
                                  {(order.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                </td>
                              </tr>
                            </>
                        }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div style={{margin: "15px"}}>
                    <div className="js-login-box" style={{
                      "textAlign": "left",
                      "fontWeight": "normal",
                      "fontSize": "12px",
                      "verticalAlign": "top!important",
                    }}>
                      <p style={{"lineHeight": "20px", "marginTop": "5px"}}>
                        <b>All amounts are in Sri Lankan Rupees (LKR)</b>
                        <br/>
                        This receipt is the invoice for your order.
                        <br/>
                        This is a computer generated invoice. No verification is required.
                      </p>
                      <font color="#888888">
                      </font>
                    </div>
                  </div>
                </div>


                <div style={{
                  "display": "flex",
                  "justifyContent": "space-between"
                }}>
                  <div style={{margin: "15px"}}>
                    <div className="js-login-box" style={{
                      "textAlign": "left",
                      "verticalAlign": "top!important",
                    }}>
                      <font color="#888888">
                        <p style={{"fontSize": "12px", "width": "680px"}}>
                          A <a style={{"color": "darkblue"}} href="https://www.techneolabs.com"
                               rel="noopener noreferrer"
                               target="_blank">TechneoLabs</a> Solution -
                          <a href="https://www.techneolabs.com" rel="noopener noreferrer"
                             target="_blank"> www.techneolabs.com</a>
                        </p>
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            </>
            }
          </div>
          <iframe id="ifmcontentstoprint" title="ifmcontentstoprint"
                  style={{"height": "0px", "width": "0px", "position": "absolute"}}></iframe>
        </div>
        <Footer/>
      </React.Fragment>
  )
}
