const Environment = {
  baseUrl: "https://hx850ltbxe.execute-api.ap-south-1.amazonaws.com/dev/"
}

if (process.env.REACT_APP_ENV === "development") {
  Environment.baseUrl = "https://hx850ltbxe.execute-api.ap-south-1.amazonaws.com/dev/"
}

if (process.env.REACT_APP_ENV === "staging") {
  Environment.baseUrl = "https://hx850ltbxe.execute-api.ap-south-1.amazonaws.com/dev/"
}

if (process.env.REACT_APP_ENV === "production") {
  Environment.baseUrl = "https://lrmp6kx0c2.execute-api.ap-south-1.amazonaws.com/prod/"
}

let BASE_URL = Environment.baseUrl;
module.exports = {

  URL_GET_PRE_SIGNED_S3_URL: BASE_URL + 'image/upload-url/',

  URL_CREATE_PRODUCT: BASE_URL + 'product/create',
  URL_GET_ALL_PRODUCT: BASE_URL + 'product/all',

  URL_GET_PRODUCT_BY_ID: BASE_URL + 'product/find/',
  URL_GET_PRODUCT_BY_CATEGORY_ID: BASE_URL + 'product/category/',

  URL_DELETE_PRODUCT_BY_PRODUCT_ID: BASE_URL + 'product/delete/',

  URL_GET_PRODUCT_DETAIL_BY_PRODUCT_ID: BASE_URL + 'product/detail/',
  URL_GET_PRODUCT_DETAIL_BY_PRODUCT_DETAIL_ID: BASE_URL + 'product/detail/find/',

  URL_CREATE_PRODUCT_CATEGORY: BASE_URL + 'product-category/create',
  URL_GET_ALL_PRODUCT_CATEGORY: BASE_URL + 'product-category/all',
  URL_GET_BY_ID_PRODUCT_CATEGORY: BASE_URL + 'product-category/find/',

  URL_CREATE_USER: BASE_URL + 'user/create',
  URL_GET_USER_BY_ID: BASE_URL + 'user/find/',

  URL_CREATE_ORDER: BASE_URL + 'order/create',
  URL_GET_ORDER_BY_ID: BASE_URL + 'order/find/',

  URL_HEARTBEAT_IMAGE: BASE_URL + 'image/version',
  URL_HEARTBEAT_USER: BASE_URL + 'user/version',
  URL_HEARTBEAT_PRODUCT: BASE_URL + 'product/version',
  URL_HEARTBEAT_PRODUCT_CATEGORY: BASE_URL + 'product-category/version',
  URL_HEARTBEAT_ORDER: BASE_URL + 'order/version',

};


