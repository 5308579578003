import React from 'react';

export default (props) => {
  return (
      <div className="c-header-search__content js-search">
        <div className="c-search__close js-close-search">
          <svg xmlns="http://www.w3.org/2000/svg" width="33.24" height="33.25" viewBox="0 0 33.24 33.25">
            <path id="x_copy_2" data-name="x copy 2" className="cls-1"
                  d="M2712.2,106.883l1.42,1.414-31.82,31.819-1.42-1.413Zm-30.4,0,31.82,31.82-1.42,1.413L2680.38,108.3Z"
                  transform="translate(-2680.38 -106.875)"/>
          </svg>
        </div>
        <form className="c-search" action="/search">
          <input type="text" className="c-search__input" placeholder="Search for products..." name="q"/>
          <button type="submit" className="c-search__btn">
            <i className="e-icons e-icons--icon-magnifier"></i>
          </button>
        </form>
      </div>
  )
}