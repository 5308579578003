import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from 'react-router-dom';

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>News</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <Link to="/" title="Home">Home</Link>
                <span aria-hidden="true">/</span>
                <span>News</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-blog">
          <div className="o-wrapper">
            <div className="o-layout o-layout--custom">
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/how-people-will-look-like-in-10-years-time">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-8.jpg 300w,
                      /images/blog-8.jpg 600w,
                      /images/blog-8.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/how-people-will-look-like-in-10-years-time">How people will look like in 10
                      years time
                    </Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/8-lessons-i-ve-learned-from-people">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-10.jpg 300w,
                      /images/blog-10.jpg 600w,
                      /images/blog-10.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/8-lessons-i-ve-learned-from-people">8 lessons I’ve learned from people</Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/eliminate-your-fears-and-doubts-about-people">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-05.jpg 300w,
                      /images/blog-05.jpg 600w,
                      /images/blog-05.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/eliminate-your-fears-and-doubts-about-people">Eliminate your fears and doubts
                      about people
                    </Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/never-underestimate-the-influence-of-people">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-03.jpg 300w,
                      /images/blog-03.jpg 600w,
                      /images/blog-03.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/never-underestimate-the-influence-of-people">Never underestimate the influence
                      of people
                    </Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/how-people-can-help-you-improve-your-health">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-9.jpg 300w,
                      /images/blog-9.jpg 600w,
                      /images/blog-9.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/how-people-can-help-you-improve-your-health">How people can help you improve
                      your health
                    </Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
              <div className="o-layout__item o-layout__item--4">
                <div className="c-blog-post">
                  <div className="c-blog-post__image">
                    <Link to="/blogs/news/7-reasons-why-people-love-people">
                      <img alt="Lookbook" data-sizes="auto" data-srcset="/images/blog-01.jpg 300w,
                      /images/blog-01.jpg 600w,
                      /images/blog-01.jpg 900w"
                           src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                           className="lazyload"/>
                    </Link>
                  </div>
                  <h6 className="c-blog-post__title">
                    <Link to="/blogs/news/7-reasons-why-people-love-people">7 reasons why people love people</Link>
                  </h6>
                  <p className="c-blog-post__date">Wed, Oct 21, 2020</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}