import React, {useEffect} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
      <React.Fragment>
        <Header/>
        <div className="c-breadcrumb">
          <div className="o-wrapper">
            <div className="o-flex o-flex--align-items-center">
              <h1>Terms of use</h1>
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <a href="/" title="Home">Home</a>
                <span aria-hidden="true">/</span>
                <span>Terms of use</span>
              </nav>
            </div>
          </div>
        </div>
        <div className="c-page">
          <div className="o-wrapper">
            <p>The following Terms of Use apply to the websites offered by KERA AG and/or its subsidiaries as well as
              the associated services (collectively referred to as “KERA Sites”). These General Terms of Use may in a
              specific case be supplemented, modified or replaced by additional terms and conditions, e.g. for the
              purchase of merchandise. You are deemed to have accepted the application of these Terms of Use at the time
              you log in or, where no special log in is required, at the time you start using a KERA site.
            </p>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
  )
}